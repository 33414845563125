import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { getPlansInfo } from "../../utils/requests/dashboardRequest";
import Loading from "../../components/shared/Loading";

const donutChartOption = {
  colors: ["#7635b3", "#1199cc", "#FEB019"],
  chart: {
    type: "donut",
    foreColor: "#fff",
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "15px ",
    },
  },
  stroke: {
    width: 0,
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: false,
      offsetX: 0,
      offsetY: 0,
      customScale: 0.8,
      dataLabels: {
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        size: "70%",
        background: "#fff",
        labels: {
          show: true,
          name: {
            show: true,
            fontSize: "22px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 500,
            offsetY: -10,
            formatter: function (val) {
              return val;
            },
          },
          value: {
            show: true,
            fontSize: "30px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            color: "#000",
            offsetY: 16,
            formatter: function (val) {
              return val;
            },
          },
          total: {
            show: true,
            showAlways: true,
            label: "Total",
            fontSize: "30px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            color: "#000",
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
  },

  labels: ["WIN-WIN", "GOLDEN", "STANDARD"],
  legend: {
    position: "bottom",
    fontSize: "16px",
    offsetY: -30,
    horizontalAlign: "center",
    itemMargin: {
      horizontal: 30,
    },
    markers: {
      width: 20,
      height: 20,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: undefined,
      radius: 12,
      customHTML: undefined,
      onClick: "bg-red",
      offsetX: -5,
      offsetY: 0,
    },
  },
};

export default function DonutChart() {
  const [loading, setLoading] = useState(false);
  const [donutChartSeries, setDonutChartSeries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading((x) => true);
      const info = await getPlansInfo(); // Replace with your API endpoint
      setLoading((x) => false);
      if (info) {
        setDonutChartSeries([info.win_win, info.golden, info.standard]);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="lg:flex justify-evenly items-center ">
        <div className="w-full lg:w-1/2">
          <p
            style={{ filter: "drop-shadow(0px 3px 1px #000000)" }}
            className="[-webkit-text-stroke-color:white] [-webkit-text-stroke-width:1px] text-transparent text-4xl sm:text-6xl text-center font-extrabold opacity-60 "
          >
            Plan summary
          </p>
          <img
            src="../dashboardDonut.svg"
            className="mix-blend-overlay hidden lg:block"
            alt=""
          />
        </div>
        <div className="grow ld:max-w-xl ">
          {loading ? (
            <div className="w-full text-center ">
              <Loading />
            </div>
          ) : (
            <Chart
              options={donutChartOption}
              series={donutChartSeries}
              type="donut"
            />
          )}
        </div>
      </div>
    </>
  );
}
