import React from "react";

export function AmountActionButton({ icon, text, value, loading }) {
  return (
    <div className="grow flex items-center justify-between rounded-xl bg-gradient-to-br from-indigo-800 to-indigo-950 p-5">
      <div>
        {loading ? (
          <p className="w-36 h-8 skeleton mb-2 bg-indigo-800"></p>
        ) : (
          <p className="text-3xl text-white font-semibold">${value}</p>
        )}
        <p className="text-stone-300">{text}</p>
      </div>
      {icon}
    </div>
  );
}
