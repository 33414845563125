export default function Table({
  children,
  headers,
  headerOptClass,
  bodyOptClass,
  optClass,
}) {
  return (
    <div
      className={`w-full max-h-[400px] overflow-auto  scrollWidth cursor-pointer rounded-xl bg-[var(--indigo-purple-dark)] my-5 relative z-201 ${optClass}`}
    >
      <table className="min-w-[1000px] w-full text-white rounded-xl overflow-hidden">
        <thead
          className={`text-left bg-[var(--indigo-purple)] [&_th]:px-4 [&_th]:py-5 capitalize ${headerOptClass}`}
        >
          <tr>
            <th>ID</th>
            {headers.map((th) => (
              <th>{th}</th>
            ))}
          </tr>
        </thead>
        <tbody
          className={`[&_td]:p-4 [&_tr:nth-child(even)]:bg-indigo-700/30 ${bodyOptClass} `}
        >
          {children}
        </tbody>
      </table>
    </div>
  );
}
