import React, { useState } from "react";
import PageStatus from "../../components/PageStatus/PageStatus";
import Pagination from "../../components/AdminShared/Pagination";
import usePost from "../../hooks/Blog/Post/usePost";
import Loading from "../../components/shared/Loading";
import { Link } from "react-router-dom";
import TableBackground from "../../components/AdminShared/TableBackground";
import Table from "../../components/AdminShared/Table";
import ErrorMessage from "../../components/shared/ErrorMessage";

export default function Post() {
  const [pagination, setPagination] = useState(1);
  const { error, loading, posts, postLinks, deletePost, setRefresh } =
    usePost(pagination);

  function handlePagination(event) {
    setPagination(event.selected + 1);
    setRefresh(true);
  }

  return (
    <>
      <PageStatus
        category={"Blog"}
        currentPage={"Post"}
        logoStarColor="#F8CC15"
      />

      <TableBackground>
        {error ? (
          <ErrorMessage />
        ) : loading ? (
          <div className="w-full h-[700px] flex items-center justify-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className=" flex items-center justify-between text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">Banner index</h3>
              <Link
                to="/dashboard/post/create"
                className="btn btn-outline btn-success "
              >
                create
              </Link>
            </div>

            <Table theads={["Id", "Title", "Description", "Slug", "Actions"]}>
              {posts.map((post) => (
                <tr>
                  <td>{post.id}</td>
                  <td>
                    <p>{post.title}</p>
                  </td>
                  <td>
                    <p>{post.description}</p>
                  </td>
                  <td>
                    <p>{post.slug}</p>
                  </td>
                  <td>{/*<p>{trader.referral_count}</p>*/}</td>
                  <th>
                    <Link
                      to={`/dashboard/post/update/${post.id}`}
                      className="btn btn-primary btn-outline  btn-sm mr-2"
                    >
                      Update
                    </Link>
                    <button
                      onClick={() => deletePost(post.id)}
                      className="btn btn-secondary btn-outline  btn-sm"
                    >
                      Delete
                    </button>
                  </th>
                </tr>
              ))}
            </Table>
          </>
        )}
        {posts.current ?? (
          <Pagination
            optClass="mt-5"
            handleOnClick={handlePagination}
            lastPage={postLinks.last_page}
          />
        )}
      </TableBackground>
    </>
  );
}
