import React from "react";

export function PlanPayBtn({ data, ...props }) {
  const balancePrice = data.balance_phases.filter(
    (item) => item.name === "Evaluation Phase 1"
  )[0];
  return (
    <div className="w-full max-w-sm grid grid-cols-1 rounded-xl text-xl text-center font-bold  bg-gradient-to-b from-indigo-800 to-[var(--indigo-purple-dark)] mx-auto my-4 relative z-10 group">
      <span className="w-full pt-2 text-white text-2xl md:text-3xl group-hover:border-yellow">
        {balancePrice.registration_fee != null &&
        balancePrice.registration_fee > 0
          ? balancePrice.registration_fee
          : balancePrice.pre_investment}
        $
      </span>

      <button
        onClick={props.onClick}
        className="w-full text-[var(--indigo-purple-dark)] hover:text-white transition-all border border-transparent hover:border-neutral-300  bg-white hover:bg-indigo-800 rounded-xl p-2 pt-2.5 capitalize text-2xl md:text-3xl font-bold"
      >
        Continue
      </button>
    </div>
  );
}
