import { createSlice } from '@reduxjs/toolkit';
// import { getData } from '../../utils/fetchData';
import { authActions } from './auth';
import { getToken } from '../../utils/getToken';
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// utils

// ----------------------------------------------------------------------

const initialState = {
    golden: null,
    standard: null,
    winWin: null,
  };
  
  const challengeReducer = createSlice({
    name: 'challenge',
    initialState,
    reducers: {
      golden(state, action) {
        state.golden = action.payload;
      },
      standard(state, action) {
        state.standard = action.payload;
      },
      winWin(state, action) {
        state.winWin = action.payload;
      },
    },
  });
  
  export const challengeActions = challengeReducer.actions;
  export default challengeReducer;

  export  function  getChallenges() {
    return async (dispatch) => {
      let response;
      const userToken = getToken()
    try {
      let token = "Bearer " + userToken;
      response = await fetch(`${baseUrl}/api/challenges`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (!response.ok) {
        console.log("Failed to fetch data");
        if (response && response.status === 401) {
          await dispatch(authActions.logout())
        }
      }
  
      const jsonData = await response.json();
      if (jsonData.challenges) {
        const challenges = jsonData.challenges;

        const goldenChallenge = challenges.find(challenge => challenge.name === 'golden');
        if (goldenChallenge) {
            dispatch(challengeActions.golden(goldenChallenge));
        }

        const winChallenge = challenges.find(challenge => challenge.name === 'win-win');
        if (winChallenge) {
            dispatch(challengeActions.winWin(winChallenge));
        }

        const standardChallenge = challenges.find(challenge => challenge.name === 'standard');
        if (standardChallenge) {
            dispatch(challengeActions.standard(standardChallenge));
        }
    }
    } catch (error) {
      if (response && response.status === 401) {
        await dispatch(authActions.logout())
  
      }
  
      return null;
    }
  }
}




