import { IoMdGift } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Bonus() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  return (
    <div
      className="block relative bg-[var(--indigo-purple-dark)] hover:bg-[var(--indigo-purple)] hover:cursor-pointer transition-all rounded-xl"
      onClick={() => navigate("referrals")}
    >
      <div className="w-full max-w-[100%] lg:max-w-[80%] relative lg:flex items-center px-5 py-3 md:py-6  mt-20 gap-40 z-10 ">
        <div className="">
          <p className="font-semibold md:font-bold  md:text-xl text-white">
            Unlock Rewards: Join Our Referral Program Now
          </p>
          <p className="font-semibold text-neutral-300  text-sm md:text-lg">
            Your Referral Code : {user.referral_token}
          </p>
        </div>
        <button className="sm:w-full lg:w-fit text-2xl text-nowrap text-neutral-200 font-semibold pt-2 pb-1 ">
          JOIN NOW!
        </button>
      </div>
      <div className="w-full h-2 bg-[#ED1C24] absolute bottom-0 ">
        <IoMdGift className="size-14 md:size-20 fill-[#ED1C24] absolute -bottom-1 right-10 " />
        <svg
          className="absolute left-[-10px] top-[-13px]"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4948 32.9775C16.4948 23.8852 22.8571 17.9282 32.7981 16.6741C24.7741 14.6316 18.5373 8.39478 16.4948 0.37075C14.4523 8.39478 8.21543 14.6681 0.191406 16.6741C8.21543 18.7166 14.4523 24.9535 16.4948 32.9775Z"
            fill="#F8CC15"
          />
        </svg>
        <svg
          className="absolute right-[-10px] top-[-13px]"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4948 32.9775C16.4948 23.8852 22.8571 17.9282 32.7981 16.6741C24.7741 14.6316 18.5373 8.39478 16.4948 0.37075C14.4523 8.39478 8.21543 14.6681 0.191406 16.6741C8.21543 18.7166 14.4523 24.9535 16.4948 32.9775Z"
            fill="#F8CC15"
          />
        </svg>
      </div>
    </div>
  );
}
