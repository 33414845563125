import { useState } from "react";
import { postData } from "../utils/fetchData";
import { toast } from "react-toastify";

const initialWithdrawData = {
  amount: null,
  withdrawFrom: null,
  type: "trc20",
  trc20_wallet_address: "",
  card_number: "",
  card_holder_name: "",
  step: 1,
};

export function useWithdraw() {
  const [withdrawData, setWithdrawData] = useState(initialWithdrawData);
  const [loading, setLoading] = useState(false);
  const handleSubmitWithdraw = async () => {
    setLoading((prev) => true);
    try {
      const res = await postData("withdraw", withdrawData);
      if (res?.status !== "success")
        throw new Error(res?.message || "Unexpected error!");

      toast.success(res?.message);
      setWithdrawData((prevData) => ({
        ...prevData,
        step: 4,
      }));
    } catch (error) {
      toast.error(error.message || "Unexpected error!");
    } finally {
      setLoading((prev) => false);
    }
  };

  function handleSetWithdraw(values) {
    setWithdrawData((prev) => ({ ...prev, ...values }));
  }

  function handleResetSetWithdraw() {
    setWithdrawData(initialWithdrawData);
  }

  return {
    loading,
    withdrawData,
    handleSubmitWithdraw,
    handleSetWithdraw,
    handleResetSetWithdraw,
  };
}
