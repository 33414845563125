export const formatErrorsAsString = (errors) => {
    const allArrays = Object.values(errors);

    // Combine all arrays into one flat array
    const allErrors = allArrays.flat();

    const errorString = allErrors.join(', ');
    console.log(errorString);

    return errorString;
};