import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  deleteData,
  getData,
  postDataImge,
  putData,
} from "../../../utils/fetchData";
import useDidMount from "../../useDidMount";
import { useNavigate } from "react-router-dom";

const usePost = (pagination, postId) => {
  const [post, setPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postLinks, setPostLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const didMount = useDidMount(true);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  const getPost = async (id) => {
    setLoading(true);
    setError(false);
    const res = await getData("post/" + id);
    setLoading(false);
    if (res?.status === "success") {
      setPost(res.post);
      return;
    }

    setError(true);
    if (res?.message) {
      toast.error(res.message);
      return;
    }
    toast.error("error fetching data");
  };

  const getPosts = async () => {
    setLoading(true);
    setError(false);
    const res = await getData("post?page=" + pagination);
    setLoading(false);
    setRefresh(false);
    if (res?.status === "success") {
      setPosts(res.posts.data);
      setPostLinks(res.posts);
      return;
    }

    setError(true);
    if (res?.message) {
      toast.error(res.message);
      return;
    }
    toast.error("error fetching data");
  };

  useEffect(() => {
    if (
      pagination &&
      ((posts.length === 0 && didMount) || (refresh && didMount))
    ) {
      getPosts();
    }
  }, [didMount, pagination, posts.length, refresh]);

  useEffect(() => {
    if (postId && post === null) {
      getPost(postId);
    }
  }, [didMount, postId]);

  const createPost = async (data) => {


    setLoading(true);
    setError(false);

    const res = await postDataImge("post", data);
    setLoading(false);

    if (res?.status === "success") {
      toast.success("Post successfully created");
      navigate("/dashboard/post");
      return;
    }

    if (res?.message) {
      toast.error(res.message);
      setError(res.message);
      return;
    }

    setError(true);
    toast.error("error posting data");
  };

  const updatePost = async (data, id) => {
    setLoading(true);
    setError(null);
    const res = await putData("post/" + id, data);
    setLoading(false);

    if (res?.status === "success") {
      toast.success("Post successfully updated");
      navigate("/dashboard/post");
      return;
    }

    setError(true);
    if (res?.message) {
      toast.error(res.message);
      setError(res.message);
      return;
    }
    toast.error("error posting data");
  };

  const deletePost = async (id) => {
    setLoading(true);
    setError(false);
    const res = await deleteData("post/" + id);
    setLoading(false);
    if (res?.status === "success") {
      toast.success("deleted Successfully");
      setRefresh(true);
      return;
    }

    setError(true);
    if (res?.message) {
      toast.error(res.message);
      return;
    }
    toast.error("error fetching data");
  };
  return {
    error,
    loading,
    posts,
    postLinks,
    createPost,
    updatePost,
    post,
    deletePost,
    setRefresh,
  };
};

export default usePost;
