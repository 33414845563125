import { useEffect, useState } from "react";
import { getData } from "../../utils/fetchData";
import { toast } from "react-toastify";
export function useVerificationRequests() {
  const [userSelected, setUserSelected] = useState(null);
  const [verificationRequests, setVerificationRequests] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  async function getRequestVerifications() {
    try {
      setLoading((prev) => true);
      setError((prev) => false);
      const res = await getData("admin-verification");

      if (res?.status !== "success")
        throw new Error(res.message || "Unexpected error!");

      if (res.verification_requests.length === 0) {
        setError((prev) => true);
        return;
      }
      setVerificationRequests(res.verification_requests);
    } catch (error) {
      toast.error(error?.message || "Unexpected error!");
    } finally {
      setLoading((prev) => false);
    }
  }

  useEffect(() => {
    if (verificationRequests == null) {
      getRequestVerifications();
    }
  }, [verificationRequests]);

  return {
    loading,
    error,
    userSelected,
    setUserSelected,
    verificationRequests,
    getRequestVerifications,
  };
}
