import { IndicatorItem } from "../../components/shared/IndicatorItem";
import { IndicatorLayout } from "../../components/shared/IndicatorLayout";
import { InitialInfoForm } from "./InitialInfoForm";
import { PaymentLayout } from "./PaymentLayout";
import { TransferDetailsLayout } from "../../components/shared/TransferDetailsLayout";
import { TransferDetail } from "../../components/shared/TransferDetail";
import { TransactionResult } from "../../components/shared/TransactionResult";
import { SubmitButton } from "../../components/shared/SubmitButton";
import PageStatus from "../../components/PageStatus/PageStatus";
import { useWithdraw } from "../../hooks/useWithdraw";
import Loading from "../../components/shared/Loading";
import { useNavigate } from "react-router-dom";
export default function Withdraw() {
  const {
    loading,
    withdrawData,
    handleSubmitWithdraw,
    handleSetWithdraw,
    handleResetSetWithdraw,
  } = useWithdraw();
  const navigate = useNavigate();

  return (
    <>
      <PageStatus
        category={"finance"}
        currentPage={"withdraw "}
        logoStarColor="#F8CC15"
      />
      <IndicatorLayout>
        <IndicatorItem
          num={1}
          text={"Initial Information "}
          currentStep={withdrawData.step}
          setStep={handleSetWithdraw}
        />
        {withdrawData.step === 1 && (
          <InitialInfoForm handleSetWithdraw={handleSetWithdraw} />
        )}

        <IndicatorItem
          num={2}
          text={"Select Payment"}
          currentStep={withdrawData.step}
          setStep={handleSetWithdraw}
        />
        {withdrawData.step === 2 && (
          <PaymentLayout
            handleSetWithdraw={handleSetWithdraw}
            withdrawData={withdrawData}
          />
        )}
        <IndicatorItem
          num={3}
          text={"Transfer Details "}
          currentStep={withdrawData.step}
          setStep={handleSetWithdraw}
        />
        {withdrawData.step === 3 && (
          <TransferDetailsLayout maxWidth="max-w-fit">
            {loading ? (
              <Loading />
            ) : (
              <>
                <TransferDetail
                  title={"Withdraw with"}
                  value={withdrawData.type}
                />
                <TransferDetail
                  title={"withdraw to"}
                  value={withdrawData.trc20_wallet_address}
                />
                <TransferDetail
                  title={"withdraw requested amount"}
                  value={withdrawData.amount}
                />
                <TransferDetail
                  title={"withdraw amount after fee"}
                  value={withdrawData.amount - 1.5}
                />
                <SubmitButton
                  onClick={handleSubmitWithdraw}
                  optClass="bg-indigo-800"
                />
              </>
            )}
          </TransferDetailsLayout>
        )}
        <IndicatorItem
          num={4}
          text={"Confirmation "}
          currentStep={withdrawData.step}
          setStep={handleSetWithdraw}
        />
        {withdrawData.step === 4 && (
          <TransactionResult
            imgUrl={"../Group 115.png"}
            headerText={"your request is submitted!"}
            firstBtnText="go to my wallets"
            firstBtnClick={() => navigate("/dashboard/wallet")}
            secBtnText="back"
            secBtnClick={() => handleResetSetWithdraw()}
          />
        )}
      </IndicatorLayout>
    </>
  );
}
