import React, { useState } from "react";
import PageStatus from "../../../../components/PageStatus/PageStatus";
import Pagination from "../../../../components/AdminShared/Pagination";
import Loading from "../../../../components/shared/Loading";
import useUserCertificate from "../../../../hooks/Admin/User/useUserCertificate";
import TableBackground from "../../../../components/AdminShared/TableBackground";
import { Link } from "react-router-dom";
export default function UserCertificate() {
  const [pagination, setPagination] = useState(1);
  const url = process.env.REACT_APP_API_BASE_URL + "/";
  const { error, loading, certificates, certificateLinks, setRefresh } =
    useUserCertificate(pagination);

  return (
    <>
      <PageStatus
        category={"Users"}
        currentPage={"Certificate"}
        logoStarColor="#F8CC15"
      />

      <TableBackground>
        {error ? (
          <p>Error loading data</p>
        ) : loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : (
          <div className="content mx-5 py-5 text-center text-white ">
            <div className=" flex items-center justify-between text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">
                User Certificate Index
              </h3>
              <Link
                to="/dashboard/admin/users"
                className="btn btn-secondary btn-outline ml-auto"
              >
                Back
              </Link>
            </div>

            <div className="overflow-x-auto mb-5">
              <table className="table">
                <thead>
                  <tr className="text-white">
                    <th>id</th>
                    <th>certificate</th>
                    <th>type</th>
                  </tr>
                </thead>
                <tbody>
                  {certificates.map((certificate) => (
                    <tr>
                      <td>{certificate.id}</td>
                      <td>
                        <a href={url + certificate.src} download>
                          {url + certificate.src}
                        </a>
                      </td>
                      <td>
                        <p>{certificate.certificate_type_name}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {certificates.current ?? (
              <Pagination
                setRefresh={setRefresh}
                setPagination={setPagination}
                current={certificateLinks.current_page}
                lastPage={certificateLinks.last_page}
              />
            )}
          </div>
        )}
      </TableBackground>
    </>
  );
}
