import React from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
export function ChatUserBubble({ item }) {
  const user = useSelector((state) => state.auth.user);
  console.log(item);
  return (
    <div className="chat chat-end">
      <div className="chat-bubble break-words bg-[#F7F5FF] shadow-md text-zinc-900">
        {item.message.startsWith(process.env.REACT_APP_API_BASE_URL) ? (
          <a href={item.message} download>
            Download File
          </a>
        ) : (
          item.message
        )}
      </div>
      <div className="chat-footer text-neutral-200 text-xs font-semibold mt-1 capitalize">
        {user.first_name}
        <time className="opacity-50 ml-1">
          {format(new Date(item.created_at), "yyyy-MM-dd HH:mm")}
        </time>
      </div>
    </div>
  );
}
