import React from "react";
import "react-toastify/dist/ReactToastify.css";
import UseProfile from "../../hooks/useProfile";
import Loading from "../../components/shared/Loading";

export function ProfilePhoto() {
  const {
    loading,
    selectedImage,
    showFile,
    handleButtonClick,
    handleImageChange,
    handleUploadImage,
    fileInputRef,
  } = UseProfile();

  const buttonClassName = `w-full max-w-[200px] rounded-xl bg-primaryPurple outline-none border-none text-xl text-white my-5 p-2${
    showFile ? "" : " hidden"
  }`;

  return (
    <>
      {loading ? (
        <Loading optClass={" block mx-auto mt-20 text-white"} />
      ) : (
        <>
          <div className="relative w-[150px] h-[150px] flex items-center justify-center">
            <img
              src={selectedImage}
              className="w-full h-full rounded-full"
              alt=""
            />
            <img
              src="../profileBorder.png"
              className="w-full h-full absolute scale-125 left-1"
              alt=""
            />
          </div>

          <button
            onClick={handleButtonClick}
            className="w-full max-w-[200px] rounded-xl bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] outline-none border-none text-xl text-white my-5 p-2"
          >
            Choose Image
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageChange}
          />

          <button onClick={handleUploadImage} className={buttonClassName}>
            Upload Image
          </button>
        </>
      )}
    </>
  );
}
