import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { postData } from "../../utils/fetchData";
import { toast } from "react-toastify";
export default function Modal({
  visible,
  setModalVisible,
  name,
  balance,
  platform,
  broker,
  wallet,
  price,
  handlebuy,
}) {
  const modalRef = useRef(null);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discoutnLoading, setDisCountLoading] = useState(false);
  async function handleCouponSubmit() {
    if (!coupon) return;
    const data = {
      token: coupon,
    };
    setDisCountLoading(true);
    const res = await postData("discount-token/evaluate", data);
    setDisCountLoading(false);
    if (res?.data) {
      setDiscount(res.data.percentage);
    } else {
      toast.error(res?.message);
      setDiscount(0);
    }
  }

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : modalRef.current.close();
  }, [visible]);

  const handleClose = () => {
    setCoupon("");
    setDiscount(0);
    setModalVisible(false);
  };

  const handleButtonClick = () => {
    handlebuy(coupon);
    handleClose();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  };

  return (
    <dialog
      ref={modalRef}
      id="my_modal_1"
      className="modal"
      onCancel={handleESC}
    >
      <div className="modal-box w-11/12 md:w-full max-w-xl bg-[var(--indigo-purple-dark)] py-20 relative px-2">
        <button
          onClick={handleClose}
          className="btn btn-sm btn-square  bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white absolute top-5 left-5 pb-1 border-indigo-600 hover:border-indigo-500"
        >
          <IoArrowBack size={20} />
        </button>
        <div className="max-w-[350px] mx-auto">
          <h4 className="text-white text-xl mb-3 font-semibold">
            Plan Details
          </h4>
          <div className="w-full  ">
            <div className="w-full flex items-center justify-between">
              <span className="text-stone-200">Plan Type:</span>
              <span className="text-stone-200 capitalize">{name}</span>
            </div>
            <div className="w-full flex items-center justify-between">
              <span className="text-stone-200">Balance:</span>
              <span className="text-stone-200">{balance}</span>
            </div>
            <div className="w-full flex items-center justify-between">
              <span className="text-stone-200">Broker:</span>
              <span className="text-stone-200">{broker}</span>
            </div>
            <div className="w-full flex items-center justify-between">
              <span className="text-stone-200">Platform:</span>
              <span className="text-stone-200">{platform}</span>
            </div>
            <div className="w-full flex items-center justify-between">
              <span className="text-white">Price:</span>
              <span className="text-white">${price}</span>
            </div>
          </div>
          <hr className="my-5" />
          <h3 className="text-white text-xl mb-3 font-semibold">
            Payment Review
          </h3>
          <div className="flex flex-col gap-2">
            <div className="w-full flex items-center text-lg justify-between">
              <p className=" text-white">Available in wallet:</p>
              <p className="text-xl text-white">${wallet}</p>
            </div>
            <div className="flex items-center justify-between [&_*]:!outline-0">
              <h4 className="mr-3 md:mr-10 text-white">Coupon: </h4>
              {name === "win-win" ? (
                <span className="text-neutral-400 text-sm">
                  Not Available For Win-Win
                </span>
              ) : (
                <div className=" join ">
                  <input
                    className="input input-sm input-bordered join-item max-w-[150px] "
                    placeholder="Coupon Code"
                    type="text"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                  />
                  <button
                    disabled={discoutnLoading}
                    onClick={handleCouponSubmit}
                    className="btn btn-sm hover:!bg-indigo-500 hover:!border-indigo-500 !bg-indigo-400 !border-indigo-400 join-item w-[60px]"
                  >
                    {discoutnLoading ? (
                      <span className="loading loading-dots loading-xs"></span>
                    ) : (
                      "Apply"
                    )}
                  </button>
                </div>
              )}
            </div>
            <div className=" w-full flex items-center  justify-between">
              <p className=" text-white">Price:</p>
              <p className="text-white">${price}</p>
            </div>

            <div className=" w-full flex items-center  justify-between">
              <p className=" text-white">Discount Amount:</p>
              {discoutnLoading ? (
                <div className="w-[60px] h-[20px] skeleton bg-[var(--indigo-purple-hover)] opacity-40 rounded-none "></div>
              ) : (
                <p className="text-error font-semibold">
                  {discount > 0 ? (
                    <>
                      <span>-${(price * (discount / 100)).toFixed(2)}</span>{" "}
                      <span>(%{discount})</span>
                    </>
                  ) : (
                    <span>$0</span>
                  )}
                </p>
              )}
            </div>
            <div className="font-semibold w-full flex items-center text-2xl justify-between ">
              <p className=" text-white">Total Pay:</p>
              {discoutnLoading ? (
                <div className="w-[80px] h-[20px] skeleton bg-[var(--indigo-purple-hover)] opacity-40 rounded-none "></div>
              ) : (
                <p className="relative text-indigo-400">
                  <span>${(price * ((100 - discount) / 100)).toFixed(2)}</span>
                </p>
              )}
            </div>
            {parseInt(wallet) >= price * ((100 - discount) / 100) ? (
              <button
                disabled={discoutnLoading}
                onClick={handleButtonClick}
                className="mx-auto mt-5 btn btn-wide text-xl transition-all hover:border-indigo-500 border-indigo-600 bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] !text-white disabled:border-indigo-600 disabled:bg-[var(--indigo-purple)] disabled:opacity-40"
              >
                Pay
              </button>
            ) : (
              <label className="w-fit mx-auto ">
                <div className="label">
                  <span className="text-lg label-text text-error ">
                    Not Enough Fund!
                  </span>
                </div>
                <Link
                  to="/dashboard/deposit"
                  className="text-white text-xl btn btn-wide hover:border-indigo-500 border-indigo-600 bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)]"
                >
                  Deposit
                </Link>
              </label>
            )}
          </div>
        </div>
      </div>
    </dialog>
  );
}
