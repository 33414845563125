import { useState, useEffect } from "react";
import { getData } from "../utils/fetchData";
import { toast } from "react-toastify";
export function useMyChallenge() {
  const [userChallenges, setUserChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  async function getChallengesData() {
    setLoading((prev) => true);
    setError((prev) => false);
    const res = await getData("user-challenges");
    setLoading((prev) => false);

    if (res?.status === "success") {
      setUserChallenges(res.userChallenges);
    } else {
      toast.error("Error fethcing data!");
      setError(true);
    }
  }

  useEffect(() => {
    getChallengesData();
  }, []);

  return { error, loading, userChallenges };
}
