import React from "react";

export function GeneralInfo({ data, dataArchive, dataChallenge }) {
  // console.log(data);
  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <p className="font-bold text-3xl text-white leading-none mt-5">
            Analyze Panel
          </p>
          <div className="flex items-center gap-x-5 font-medium [&>*]:border-none mb-5 mt-3">
            {/* <p className="badge badge-lg bg-slate-300 text-slate-800  ">
          last update time: 4 minutes ago
        </p> */}
            <p className="badge badge-lg badge-info text-black font-bold pt-1 ml-3">
              All times are in GMT
            </p>
            {dataChallenge.status === "inactive" && (
              <p className="badge badge-lg bg-[#CB0000] text-white pt-1">
                Inactive
              </p>
            )}
          </div>
        </div>
        <img src="../../../analyzePanelIcon.png" className="w-36" alt="" />
      </div>
      
      <div className="w-full grid grid-cols-2 sm:grid-cols-4  gap-3 [&>div]:shadow [&>div]:rounded-lg [&>div]:bg-gradient-to-br [&>div]:from-indigo-800 [&>div]:to-[var(--indigo-purple-dark)]  py-7 relative z-50">
        {data !== null && dataArchive !== null && (
          <>
            <GeneralInfoStat title={"Balance"} value={data.balance} />
            <GeneralInfoStat title={"Equity"} value={data.equity} />
            <GeneralInfoStat
              title={"Today Profit"}
              value={data.equity - dataArchive.equity}
            />
            {data.profit_target && (
            <GeneralInfoStat
              title={"Target Profit"}
              value={data.profit_target}
            />
            )}
          </>
        )}
      </div>
    </>
  );
}
function GeneralInfoStat({ title, value }) {
  return (
    
    <div className="text-center py-3">
      <p className="text-white text-xl font-normal mb-2"> {title} </p>
      <p className="text-white font-semibold text-3xl">
        ${Intl.NumberFormat().format(Number(value))}
      </p>
    </div>
    
  );
}
