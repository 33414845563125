import React, { useContext } from "react";
import { StateProvider } from "./StatesContextProvider";

export function TicketSearch() {
  const { search, setSearch } = useContext(StateProvider);
  return (
    <label className="input border border-gray-300 bg-transparent flex items-center gap-2 focus:broder-gray-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="white"
        className="w-6 h-6 opacity-70"
      >
        <path
          fillRule="evenodd"
          d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
          clipRule="evenodd"
        />
      </svg>
      <input
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        type="text"
        className="grow"
        placeholder="Search Ticket"
      />
    </label>
  );
}
