import React, { useRef, useState } from "react";
import Loading from "../../components/shared/Loading";
import { toast } from "react-toastify";
import { IoIosAttach } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import ErrorMessage from "../../components/shared/ErrorMessage";
import { useNewTicket } from "../../hooks/useNewTicket";

export function NewTicketForm({ newTicketSuccess }) {
  const title = useRef();
  const message = useRef();
  const department = useRef();
  const modalClose = useRef();
  const fileUpload = useRef();
  const [uploadedFileName, setUploadedFileName] = useState("");
  const { loading, submitLoading, handleUploadTicket, departments } =
    useNewTicket();

  function handleFileUploadClick() {
    fileUpload.current.click();
  }

  function handleSetFileName(e) {
    setUploadedFileName(e.target.files[0].name);
  }

  const postTicket = async (e) => {
    if (
      department.current.value == null ||
      title.current.value == null ||
      message.current.value == null
    ) {
      toast.error("Please fill fields!");
      return;
    }

    e.preventDefault();
    const formData = new FormData();
    fileUpload.current.files[0] &&
      formData.append("file", fileUpload.current.files[0]);
    formData.append("department_id", department.current.value);
    formData.append("title", title.current.value);
    formData.append("message", message.current.value);

    await handleUploadTicket(formData, modalClose, newTicketSuccess);

    setUploadedFileName("");
    fileUpload.current.value = null;
    department.current.value = null;
    title.current.value = null;
    message.current.value = null;
  };

  return (
    <dialog id="newTicket" className="modal !outline-0">
      <form
        onSubmit={postTicket}
        className={`modal-box bg-[var(--indigo-purple-dark)] w-full max-w-2xl mb-10 rounded-xl p-5 flex flex-col [&>*]:!outline-0  shadow-lg `}
      >
        <button
          type="button"
          onClick={() => modalClose.current.click()}
          className="btn btn-sm btn-square bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] border hover:border-indigo-500 border-indigo-600 pb-1 text-white"
        >
          <IoArrowBack size={20} />
        </button>
        {loading || departments === null ? (
          <Loading />
        ) : (
          <div className="flex items-center gap-3 border-b-2 border-white px-2 mt-8">
            <label
              htmlFor="department"
              className="text-white text-nowrap capitalize label"
            >
              choose department:
            </label>
            <select
              required
              ref={department}
              id="department"
              className="w-full bg-transparent text-lg text-white font-semibold outline-0"
            >
              {departments?.map((department, key) => (
                <option
                  className="bg-[var(--indigo-purple-dark)]"
                  key={key}
                  value={department.id}
                >
                  {department.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="flex items-center gap-3 border-b-2 border-white px-2 mt-8">
          <label
            htmlFor="title"
            className="text-white text-nowrap capitalize label"
          >
            title:
          </label>
          <input
            ref={title}
            required={true}
            id="title"
            className="w-full bg-transparent text-lg text-white font-semibold outline-0 placeholder:text-white"
            type="text"
            placeholder=""
          />
        </div>

        <div className="mt-8">
          <label
            htmlFor="text"
            className="text-white text-nowrap capitalize label"
          >
            message:
          </label>
          <textarea
            ref={message}
            required={true}
            id="text"
            className="textarea border-2 border-white bg-transparent text-white  w-full p-2  focus:!border-white placeholder:text-white font-semibold"
            rows={3}
            placeholder="Your Message"
          />
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="text-white font-semibold">{uploadedFileName}</p>
          <input
            type="file"
            ref={fileUpload}
            style={{ display: "none" }}
            accept="*"
            hidden
            onChange={handleSetFileName}
          />

          <button
            type="button"
            className="btn bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white !border-transparent !outline-none max-w-32 gap-0 "
            onClick={handleFileUploadClick}
          >
            <IoIosAttach size={20} />
            Attach
          </button>
        </div>
        <ErrorMessage
          text="The file must not be greater than 2MB."
          optcClass="mb-8"
        />

        <button
          disabled={submitLoading}
          className=" btn !bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] border hover:border-indigo-500 border-indigo-600 !text-white !outline-0 disabled:opacity-70"
        >
          Send
          {submitLoading && (
            <span className="loading loading-dots loading-sm"></span>
          )}
        </button>
      </form>

      <form method="dialog" className="modal-backdrop">
        <button ref={modalClose}>close</button>
      </form>
    </dialog>
  );
}
