import React, { useContext } from "react";
import { FaFilter } from "react-icons/fa";
import { StateProvider } from "./StatesContextProvider";

export function TicketFiltersFilter() {
  const { filterOption, setFilterOption } = useContext(StateProvider);
  return (
    <label htmlFor="filter " className="relative">
      <FaFilter
        size={15}
        className="absolute right-3 top-1/2 -translate-y-1/2"
      />
      <select
        onChange={(e) => setFilterOption(e.target.value)}
        value={filterOption}
        id="filter"
        className="select border-gray-300 w-full max-w-xs bg-transparent capitalize !outline-none bg-none text-center text-base"
      >
        <option value="">Filter</option>
        <option value="date">Date</option>
        <option value="Closed">Closed</option>
        <option value="Resolved">Resolved</option>
        <option value="Pending">Pending</option>
      </select>
    </label>
  );
}
