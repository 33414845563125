import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getData } from "../../../utils/fetchData";
import useDidMount from "../../useDidMount";
import { useParams } from "react-router-dom";

const useUserCertificate = (pagination) => {
  const [certificates, setCertificates] = useState([]);
  const [certificateLinks, setCertificateLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const didMount = useDidMount(true);
  const [refresh, setRefresh] = useState(false);

  const { id } = useParams();

  const getCertificates = async () => {
    setLoading(true);
    setError(false);
    const res = await getData("certificate/" + id);
    setLoading(false);
    setRefresh(false);
    if (res?.status === "success") {
      setCertificates(res.certificates.data);
      setCertificateLinks(res.certificates);
      return;
    }

    setError(true);
    if (res?.message) {
      toast.error(res.message);
      return;
    }
    toast.error("error fetching data");
  };

  useEffect(() => {
    if (
      pagination &&
      ((certificates.length === 0 && didMount) || (refresh && didMount))
    ) {
      getCertificates();
    }
  }, [didMount, pagination, certificates.length, refresh]);

  return {
    error,
    loading,
    certificates,
    certificateLinks,
    setRefresh,
  };
};

export default useUserCertificate;
