import { createSlice } from '@reduxjs/toolkit';
import { authActions } from './auth';
import { getToken } from '../../utils/getToken';
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// import { getData } from '../../utils/fetchData';
// utils

// ----------------------------------------------------------------------

const initialState = {
    country : []
  };
  
  const countryReducer = createSlice({
    name: 'country',
    initialState,
    reducers: {
      country(state, action) {
          state.country = action.payload;
      },
    },
  });
  
  export const countryActions = countryReducer.actions;
  export default countryReducer;

  export  function  getCountries() {
    return async (dispatch) => {
      let response;
    const userToken = getToken()
  try {
    let token = "Bearer " + userToken;
    response = await fetch(`${baseUrl}/api/countries`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!response.ok) {
      console.log("Failed to fetch data");
      if (response && response.status === 401) {
        await dispatch(authActions.logout())
      }
    }

    const jsonData = await response.json();
    if (jsonData.countries) {
      dispatch(countryActions.country(jsonData.countries));
    }
} catch (error) {
    if (response && response.status === 401) {
      await dispatch(authActions.logout())

    }

      return null;
    }
  }
}

