import React from "react";
import { goldenStartTime } from "../../utils/goldenStartTime";
export function PendingRow({ data }) {
  let startTime;
  if (data.challenge === "golden" && data.start_time === null) {
    startTime = goldenStartTime();
  }
  return (
    <tr className="text-neutral-100 ">
      <td>{data.id}</td>
      <td>
        <span
          className={`${
            data.inactive_reason === "fail"
              ? "text-error"
              : data.inactive_reason === "success"
              ? "text-success"
              : ""
          } capitalize`}
        >
          {data.inactive_reason}
        </span>
      </td>
      <td>${data.balance}</td>
      <td className="capitalize">{data.challenge_type_name}</td>
      <td className="text-amber-400 text-lg">{data.phase_type_name}</td>
      <td>
        {data.start_time !== 0 ? data.start_time : "not started"}
        {startTime ?? "-"}
      </td>

      <td>{data.end_time ?? "-"}</td>
    </tr>
  );
}
