import { useEffect, useState } from "react";
import { getData } from "../../utils/fetchData";
import { toast } from "react-toastify";

export function useWithdrawRequest() {
  const [userSelected, setUserSelected] = useState(null);
  const [withdrawRequests, setWithdrawRequests] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  async function getRequestWithdraws() {
    setLoading((prev) => true);
    setError((prev) => false);

    const res = await getData("withdraw");
    if (res?.status === "success") {
      if (res.withdraw_requests.length === 0) {
        setError((prev) => "Data not found!");
      } else {
        setWithdrawRequests(res.withdraw_requests);
      }
    } else {
      setError("Error fetching data!");
      toast.error(res?.message || "Error fetching data!");
    }

    setLoading((prev) => false);
  }

  useEffect(() => {
    if (withdrawRequests == null) {
      getRequestWithdraws();
    }
  }, [withdrawRequests]);

  return {
    loading,
    error,
    userSelected,
    setUserSelected,
    withdrawRequests,
    getRequestWithdraws,
  };
}
