import { useState } from "react";
import { postData } from "../utils/fetchData";
import { toast } from "react-toastify";

export function usePaymentRequests() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [paymentId, setPaymentId] = useState(null);

  async function handleSubmit() {
    if (!paymentId) return;

    const data = {
      payment_id: paymentId,
    };

    setLoading((prev) => true);
    setError((prev) => false);
    const res = await postData("now-payments/confirm", data);
    setLoading((prev) => false);

    if (res?.status === "success") {
      toast.success(res.message);
    } else {
      toast.error(res?.message || "Error fetching data!");
    }
    setError(`${res?.message} (id: ${paymentId})`);

    setPaymentId(null);
  }

  return { loading, error, paymentId, setPaymentId, handleSubmit };
}
