import React from "react";

import { SiWebmoney } from "react-icons/si";

import { useEffect, useState } from "react";
import { getStats } from "../../utils/requests/dashboardRequest";
import { LiaUsersSolid } from "react-icons/lia";
import { RiRefund2Fill } from "react-icons/ri";
import { MdOutlinePaid } from "react-icons/md";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { MdOutlineSignalCellularAlt } from "react-icons/md";

const content = [
  {
    text: "active traders",
    icon: <LiaUsersSolid />,
  },
  {
    text: "paid profit",
    icon: <MdOutlinePaid />,
  },
  {
    text: "total capital under management",
    icon: <HiOutlineBanknotes />,
  },
  {
    text: "average toatal capital profit",
    icon: <RiRefund2Fill />,
  },
  {
    text: "WinWin capital fund",
    icon: <MdOutlineSignalCellularAlt />,
  },
  {
    text: "average capital profit",
    icon: <SiWebmoney />,
  },
];

export default function CompactInfo() {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    activeTraders: "",
    paidProfit: "",
    totalCapitalUnderManagment: "",
    averageTotalCapitalProfit: "",
    winWinCapitalFund: "",
    averageCapitalProfit: "",
  });

  useEffect(() => {
    const fetchStats = async () => {
      setLoading((x) => true);
      const statsFetched = await getStats();
      if (statsFetched) {
        setStats({
          activeTraders: statsFetched.active_traders,
          paidProfit: statsFetched.paid_profit,
          totalCapitalUnderManagment:
            statsFetched.total_capital_under_managment,
          averageTotalCapitalProfit: statsFetched.average_total_capital_profit,
          winWinCapitalFund: statsFetched.win_win_capital_fund,
          averageCapitalProfit: statsFetched.average_capital_profit,
        });
      }
      console.log(statsFetched);
      setLoading(false);
    };

    fetchStats();
  }, []);

  return (
    <div className="w-full grid grid-cols-2 sm:grid-cols-3  gap-3 [&>div]:shadow [&>div]:rounded-lg [&>div]:bg-gradient-to-br [&>div]:from-indigo-800 [&>div]:to-[var(--indigo-purple-dark)]  py-7 relative z-50">
      <CompactInfoText
        text={content[0].text}
        icon={content[0].icon}
        title={stats.activeTraders}
        loading={loading}
      />
      <CompactInfoText
        text={content[1].text}
        icon={content[1].icon}
        title={stats.paidProfit}
        loading={loading}
      />
      <CompactInfoText
        text={content[2].text}
        icon={content[2].icon}
        title={stats.totalCapitalUnderManagment}
        loading={loading}
      />
      <CompactInfoText
        text={content[3].text}
        icon={content[3].icon}
        title={stats.averageTotalCapitalProfit}
        loading={loading}
      />
      <CompactInfoText
        text={content[4].text}
        icon={content[4].icon}
        title={stats.winWinCapitalFund}
        loading={loading}
      />
      <CompactInfoText
        text={content[5].text}
        icon={content[5].icon}
        title={stats.averageCapitalProfit}
        loading={loading}
      />
    </div>
  );
}

function CompactInfoText({ title, text, icon, loading }) {
  return (
    <div className="flex items-center justify-center flex-col-reverse md:flex-row px-3 py-2 xl:px-16 lg:py-2">
      <div className=" grow text-center md:text-left my-2 w-full">
        {loading ? (
          <p className="skeleton w-2/3 h-8 hidden md:block mb-2 bg-transparent"></p>
        ) : (
          <h4 className="font-bold text-xl xl:text-3xl leading-none text-white hidden md:block">
            {title}
          </h4>
        )}
        <p className="text-white leading-none capitalize text-left">{text}</p>
      </div>
      <div className="w-full flex items-center justify-between [&_svg]:text-white md:w-auto ">
        {loading ? (
          <p className="skeleton w-2/3 h-8 block md:hidden mb-2 bg-[#6131e5]"></p>
        ) : (
          <h4 className="font-bold text-xl xl:text-3xl leading-none mt-1 text-white block md:hidden">
            {title}
          </h4>
        )}
        <div className="w-10 h-10 xl:w-12 xl:h-12 [&_svg]:w-full [&_svg]:h-full">
          {icon}
        </div>
      </div>
    </div>
  );
}
