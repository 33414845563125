import { postData } from "../../../utils/fetchData";
import { toast } from "react-toastify";
import Loading from "../../../components/shared/Loading";
import TableBackground from "../../../components/AdminShared/TableBackground";
import Table from "../../../components/AdminShared/Table";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import RequestDetailsForm from "../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../components/AdminShared/RequestsDetailsButton";
import { useVerificationRequests } from "../../../hooks/Admin/useVerificationRequests";
import PageStatus from "../../../components/PageStatus/PageStatus";
import { useState } from "react";
const tHeadData = ["Name", "Email", "Country", "Action"];

export default function AdminVerification() {
  const {
    loading,
    error,
    userSelected,
    setUserSelected,
    verificationRequests,
    getRequestVerifications,
  } = useVerificationRequests();

  return (
    <>
      <PageStatus
        category={"Users"}
        currentPage={"Verification"}
        logoStarColor="#F8CC15"
      />
      {userSelected ? (
        <ChallengeRequestDetail
          userSelected={userSelected}
          setUserSelected={setUserSelected}
          getRequestVerifications={getRequestVerifications}
        />
      ) : (
        <TableBackground>
          {loading ? (
            <div className="w-full text-center">
              <Loading />
            </div>
          ) : error ? (
            <ErrorMessage />
          ) : (
            <Table theads={tHeadData}>
              {verificationRequests?.map((data) => (
                <VerificationRequestRow
                  key={data.email}
                  data={data}
                  setUserSelected={setUserSelected}
                />
              ))}
            </Table>
          )}
        </TableBackground>
      )}
    </>
  );
}

export function VerificationRequestRow({ data, setUserSelected }) {
  const { first_name, last_name, country, email } = data;

  function onUserSelected() {
    setUserSelected(data);
  }

  return (
    <tr>
      <th>{first_name + " " + last_name}</th>
      <td>{email}</td>
      <td>{country}</td>
      <td>
        <div className="flex items-center gap-x-2">
          <button
            onClick={onUserSelected}
            className="btn btn-sm btn-info btn-outline"
          >
            Details
          </button>
        </div>
      </td>
    </tr>
  );
}

function ChallengeRequestDetail({
  userSelected,
  setUserSelected,
  getRequestVerifications,
}) {
  const [declineMessage, setDeclineMessage] = useState("");
  const handleSubmit = async (verify) => {
    if (verify === 0 && declineMessage === "") {
      return toast.error("Please enter a decline message!");
    }
    const formData = {
      user_id: userSelected.id,
      verify: verify,
      decline_message: declineMessage,
    };
    try {
      const res = await postData("admin-verify", formData);

      if (res?.status !== "success")
        throw new Error(res.message || "Unexpected error!");

      toast.success(res.message);
    } catch (error) {
      toast.error(error.message || "error posting data");
    } finally {
      handleCancel();
      getRequestVerifications();
    }
  };

  function handleCancel() {
    setUserSelected(null);
  }

  return (
    <TableBackground optClass="max-w-3xl">
      <RequestDetailsForm>
        <RequestDetailsInput
          title="Name"
          value={userSelected.first_name + " " + userSelected.last_name}
          disabled
        />
        <RequestDetailsInput
          title="Country"
          value={userSelected.country}
          disabled
        />
        <RequestDetailsInput
          title="Email"
          value={userSelected.email}
          disabled
        />
        <RequestDetailsInput
          title="Phone"
          value={userSelected.phone}
          disabled
        />
        <RequestDetailsInput
          title="Identity"
          value={userSelected.type}
          disabled
        />

        <div className="col-span-3 row-start-3">
          <RequestDetailsInput
            title="Decline Reason"
            value={declineMessage}
            onChange={(e) => setDeclineMessage(e.target.value)}
          />
        </div>
        <label htmlFor="image" className="form-control row-start-4">
          <div className="label">
            <span className="label-text">identity image :</span>
          </div>
          <img src={userSelected.img} alt="" />
        </label>
      </RequestDetailsForm>

      <RequestDetailsButtonLayout>
        <RequestDetailsButton
          title="Verify"
          onclick={() => handleSubmit(1)}
          optClass="btn-success"
        />
        <RequestDetailsButton
          title="Decline"
          onclick={() => handleSubmit(0)}
          optClass="btn-error"
        />
        <RequestDetailsButton
          title="Cancel"
          onclick={handleCancel}
          optClass="btn-warning"
        />
      </RequestDetailsButtonLayout>
    </TableBackground>
  );
}
