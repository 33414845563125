import React from "react";

export function PlanSetupIcon({ data, active, setData }) {
  function handleClick() {
    setData((prev) => ({ ...prev, accountType: data.type }));
  }

  return (
    <button onClick={handleClick}>
      <div
        className={`w-fit h-fit relative  my-4 mb-10 ${
          active === data.type ? "opacity-100 scale-[1.2]" : "opacity-50"
        } transition-all hover:scale-[1.2]`}
      >
        <div className="w-24 h-24 md:w-32 md:h-32 flex items-center justify-center rounded-3xl bg-[var(--indigo-purple-dark)] border border-white p-3 relative z-10">
          <div className="w-3/4 h-3/4">
            <img src={data.svg} alt="" />
          </div>
        </div>
        <div
          className={`${
            active !== data.type ? "hidden" : "flex"
          } w-full h-full top-8 items-end justify-center text-[var(--indigo-purple-dark)] text-md md:text-xl font-bold bg-white rounded-3xl p-1 absolute z-0`}
        >
          <p className="capitalize">{data.text}</p>
        </div>
      </div>
    </button>
  );
}
