import { deleteData, getData } from "../../utils/fetchData";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export function useBanner() {
  const [type, setType] = useState("index");
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getBanners = async () => {
    setLoading((prev) => true);
    setError((prev) => false);
    const res = await getData("banners");

    if (res?.status === "success") {
      setBanners(res.banners);
    } else {
      setError((prev) => "Error fetching data!");
      toast.error(res.message || "Unexpected error!");
    }

    setLoading((prev) => false);
  };

  const deleteBanners = async (id) => {
    setLoading((prev) => true);
    setError((prev) => false);
    const res = await deleteData("banners/" + id);

    if (res?.status === "success") {
      getBanners();
      toast.success(res.message);
    } else {
      toast.error(res.message || "Unexpected error!");
      setLoading((prev) => false);
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  return { loading, error, setType, deleteBanners, getBanners, type, banners };
}
