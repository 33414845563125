import React from "react";
import { PlanSetupIcon } from "./PlanSetupIcon";
import { PlanSetupTable } from "./PlanSetupTable";
import { PlanBalanceBtnLayout } from "./PlanBalanceBtnLayout";
import { PlanBalanceBtn } from "./PlanBalanceBtn";
import { PlanPayBtn } from "./PlanPayBtn";
import Modal from "./Modal";
import { challengePlans } from "./Plans";

import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/shared/Loading";
import { useNewChallenge } from "../../hooks/useNewChallenge";

const brokerImg = {
  Swissquote: "../swissquote-broker.png",
  Uniglobe: "../uniglogbe-broker.png",
  HYCM: "../hycm-broker.png",
  Axi: "../axi-broker.png",
  OANDA: "../oanda_broker.png",
};

export function PlanInfo({ data, setData }) {
  const {
    loading,
    modalVisible,
    setModalVisible,
    handleModalVisible,
    challenge,
    balance,
    platform,
    broker,
    wallet,
    balancePrice,
    handlebuy,
    setBalance,
    handlePlatformChange,
    setBroker,
    brokers,
  } = useNewChallenge(data);
  return (
    <div className="max-w-7xl mx-auto relative">
      <Modal
        visible={modalVisible}
        setModalVisible={setModalVisible}
        name={challenge?.name}
        balance={balance?.amount}
        platform={platform}
        broker={broker}
        wallet={wallet}
        price={
          balancePrice.registration_fee != null &&
          balancePrice.registration_fee > 0
            ? balancePrice.registration_fee
            : balancePrice.pre_investment
        }
        handlebuy={handlebuy}
      />

      {loading ? (
        <div className="w-full text-center">
          <Loading />
        </div>
      ) : (
        <>
          <div className="relative z-10 grid grid-cols-3 justify-items-center items-start rounded-xl py-5">
            {challengePlans.map((item) => (
              <PlanSetupIcon
                data={item}
                key={item.type}
                active={data.accountType}
                setData={setData}
              />
            ))}
          </div>
          {!balance ? (
            <div className="w-full h-[720px] bg-[var(--indigo-purple-dark)] rounded-2xl flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <PlanSetupTable data={balance} />
          )}
          <PlanBalanceBtnLayout>
            {!balance || !challenge ? (
              <>
                <div className="w-full h-[40px] indigo-gradient rounded-xl">
                  <span className="skeleton w-full h-full bg-transparent" />
                </div>
                <div className="w-full h-[40px] indigo-gradient rounded-xl">
                  <span className="skeleton w-full h-full bg-transparent" />
                </div>
              </>
            ) : (
              challenge.challenge_balances.map(
                (btn, index) =>
                  btn.status === "active" && (
                    <PlanBalanceBtn
                      balance={balance}
                      key={index}
                      id={btn}
                      setBalance={setBalance}
                    />
                  )
              )
            )}
          </PlanBalanceBtnLayout>
          <div className="flex flex-col xl:flex-row xl:items-center justify-between relative gap-5 !z-10 my-10">
            <div className="flex flex-col md:flex-row justify-between xl:justify-normal items-center md:gap-3 ">
              <label
                htmlFor="chooseBroker"
                className="text-white text-lg text-nowrap capitalize"
              >
                choose your platform:
              </label>

              <fieldset
                className="flex justify-between rounded-lg gap-6 p-1 indigo-gradient w-[285px] outline-0"
                onChange={(e) => handlePlatformChange(e.target.value)}
              >
                <label className="label gap-1 cursor-pointer">
                  <img
                    src="../mt4.webp"
                    className="w-7 h-7 max-w-10 max-h-10 rounded-lg"
                    alt=""
                  />
                  <span className="label-text pt-1 ml-1 mr-0.5 text-white font-semibold">
                    MT4
                  </span>
                  <input
                    value="MT4"
                    checked={platform === "MT4"}
                    type="radio"
                    name="platform"
                    className="radio radio-sm border border-white"
                  />
                </label>
                <label className="label gap-1 cursor-pointer">
                  <img
                    src="../mt5.webp"
                    className="w-7 h-7 max-w-10 max-h-10 rounded-lg"
                    alt=""
                  />
                  <span className="label-text pt-1 ml-1 mr-0.5 text-white font-semibold">
                    MT5
                  </span>
                  <input
                    value="MT5"
                    checked={platform === "MT5"}
                    type="radio"
                    name="platform"
                    className="radio radio-sm border-white"
                  />
                </label>
              </fieldset>
            </div>

            <div className="flex flex-col md:flex-row justify-between xl:justify-normal items-center md:gap-3 mt-5 lg:mt-0">
              <label
                htmlFor="chooseBroker"
                className="text-white text-lg text-nowrap capitalize"
              >
                choose your broker:
              </label>
              <div className=" flex rounded-lg gap-3 p-2.5 indigo-gradient w-[285px] outline-0 ">
                <div className="max-w-[60px] flex items-center justify-center ">
                  <img
                    src={brokerImg[broker] || "../newChallengeBroker.png"}
                    alt=""
                  />
                </div>
                <select
                  onChange={(e) => setBroker(e.target.value)}
                  id="chooseBroker"
                  value={broker || "default"}
                  className="bg-transparent w-full  outline-0 text-white  text-lg font-bold"
                >
                  <option value="default" disabled>
                    Please select a broker
                  </option>
                  {brokers.map((broker, index) => (
                    <option
                      className="bg-[var(--indigo-purple-dark)]"
                      key={index}
                      value={broker}
                    >
                      {broker}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {balance != null && (
            <PlanPayBtn onClick={handleModalVisible} data={balance} />
          )}
          {/* <img
            src="../dashboardBgWave.png"
            className="absolute top-0 mix-blend-color-burn"
            alt=""
          /> */}
        </>
      )}
    </div>
  );
}
