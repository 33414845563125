import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postData } from "../utils/fetchData";
export function useForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const getEmail = async () => {
    setLoading((prev) => true);
    const res = await postData("email-exists", { email: email });
    setLoading((prev) => false);
    if (res?.status === "success") {
      navigate(`/reset-password/${encodeURIComponent(email)}`);
    } else {
      toast.error("email doesnt exists!");
      // setError(true);
    }
  };
  const handleSubmit = async (e) => {
    if (emailPattern.test(email)) {
      getEmail();
    } else {
      setError(true);
    }
  };
  function handleSetEmail(val) {
    setEmail((prev) => val);
  }

  return { email, loading, error, handleSubmit, handleSetEmail };
}
