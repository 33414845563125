import { useEffect, useState } from "react";
import { getData, putData } from "../../utils/fetchData";
import { toast } from "react-toastify";
const initialFormData = {
  id: null,
  name: null,
  rank: null,
  gf_factor: null,
  nation: null,
  withdraw: null,
};

export function useWithdrawTopTrader() {
  const [type, setType] = useState("index");
  const [traders, setTraders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const getTraders = async () => {
    setLoading((prev) => true);
    setError((prev) => false);

    const res = await getData("maximum-withdraw");
    if (res?.status === "success") {
      setTraders(res.maximum_withdraws);
    } else {
      setError((prev) => "Error fetching data!");
      toast.error(error?.message || "Unexpected error!");
    }

    setLoading((prev) => false);
  };
  const handleUpdate = async () => {
    setLoading((prev) => true);

    const res = await putData(
      "maximum-withdraws/" + formData.id,
      formData
    );

    if (res?.status === "success") {
      toast.success("successfully updated!");
      getTraders();
      setType("index");
    } else {
      setLoading((prev) => false);
      toast.error(error?.message || "Unexpected error!");
    }
  };

  useEffect(() => {
    getTraders();
  }, []);
  return {
    loading,
    error,
    type,
    setType,
    formData,
    setFormData,
    traders,
    handleUpdate,
  };
}
