import React from "react";
import { TicketFiltersFilter } from "./TicketFiltersFilter";
import { TicketSearch } from "./TicketSearch";

export function TicketFilters() {
  return (
    <div className="w-full flex items-center gap-x-3 ">
      <TicketSearch />
      <TicketFiltersFilter />
    </div>
  );
}
