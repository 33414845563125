import React from "react";
import { TradeHistoryRow } from "./RowData";
import StatusLogo from "../../../components/PageStatus/StatusLogo";
import { AnalysisStatus } from "./AnalysisStatus";
import { GeneralInfo } from "./GeneralInfo";
import { ChallengeStatistics } from "./ChallengeStatistics";
import { HistoryChart } from "./HistoryChart";
import Loading from "../../../components/shared/Loading";
import DownloadCSV from "../DownloadCSV";
import { useAnalyze } from "../../../hooks/useAnalyzePanel";
import Table from "../../../components/Table";
// import { logDOM } from "@testing-library/react";
// import DownloadButton from "../shared/DownloadButton";


const headers = [
  "symbol",
  "type",
  "status",
  "Profit/Loss",
  "Pips",
  "open time",
  "close time",
  "lot",
  "duration",
  "open price",
  "close price",
];
export default function AnalyzePanel({ match }) {
 const {loading, totals, trades, totalsArchive, totalArchives, thisChallenge, tadeLoading} = useAnalyze()
  return (
    <>
      <StatusLogo category="analyze panel" />
      {loading ? (
        <Loading optClass={"block mx-auto mt-20"} />
      ) : (
        <>
          <GeneralInfo
            data={totals}
            dataArchive={totalsArchive}
            dataChallenge={thisChallenge}
          />
          <AnalysisStatus
            data={totals}
            dataArchive={totalsArchive}
            dataChallenge={thisChallenge}
          />
          <HistoryChart dataArchives={totalArchives} total={totals} />
        </>
      )}
      {tadeLoading ? (
        <Loading optClass={"block mx-auto mt-20"} />
      ) : (
        <>
          {(trades != null && totalArchives!=null && totals!= null) && <ChallengeStatistics data={trades} archives={totalArchives} total={totals} primaryBalance={thisChallenge.balance} />}
          {
            trades && 
            <div className="pt-12">
              <Table headers={headers}>
                {trades.map((row, index) => (
                  <TradeHistoryRow row={row} index={index} />
                ))}
              </Table>
              <DownloadCSV data={trades}/> 
            </div>
           
          }
        </>
      )}
    </>
  );
}
