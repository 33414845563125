import ReactPaginate from "react-paginate";
const style =
  "join-item btn text-white bg-transparent hover:bg-[var(--indigo-purple-hover)] border !border-[var(--indigo-purple-hover)]";
const Pagination = ({ handleOnClick, lastPage, optClass }) => {
  return (
    <ReactPaginate
      className={`w-full items-center justify-center join [&_*]:text-xs sm:[&_*]:text-sm ${optClass}`}
      activeLinkClassName="!bg-[var(--indigo-purple)]"
      pageLinkClassName={`${style} px-2 sm:px-4`}
      previousLinkClassName={`${style} w-[50px] sm:w-[110px] !bg-[var(--indigo-purple)] hover:!bg-[var(--indigo-purple-hover)]`}
      nextLinkClassName={`${style} w-[50px] sm:w-[110px] !bg-[var(--indigo-purple)] hover:!bg-[var(--indigo-purple-hover)]`}
      breakLinkClassName={`${style} w-[50px]`}
      breakLabel="..."
      nextLabel="next >"
      onPageChange={(event) => handleOnClick(event)}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      pageCount={lastPage}
      previousLabel="< previous"
      renderOnZeroPageCount={null}
    />
  );
};

export default Pagination;
