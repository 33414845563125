import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getData, postData } from "../utils/fetchData";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const mt4Brokers = ["Swissquote", "Uniglobe", "HYCM", "Axi"];
const mt5Brokers = ["Uniglobe", "HYCM", "Swissquote", "OANDA"];

export function useNewChallenge(data) {
  const challenges = useSelector((state) => state.challenge);
  const [challenge, setChallenge] = useState(null);
  const [balance, setBalance] = useState(null);
  const [broker, setBroker] = useState("");
  const [brokers, setBrokers] = useState(mt4Brokers);
  const [platform, setPlatform] = useState("MT4");
  const [modalVisible, setModalVisible] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [loading, setLoading] = useState(false);
  let balancePrice = 0;

  const navigate = useNavigate();

  const handlebuy = async (token) => {
    if (broker === "") {
      toast.error("please select you broker");
      return;
    }

    const formData = {
      balance_id: balance.id,
      broker: broker,
      platform: platform,
      token: token
    };

    setLoading(true);
    const res = await postData("user-challenges-pay", formData);
    setLoading(false);
    if (res?.status === "success") {
      const message = res.message;
      toast.success(message);
      navigate("/dashboard/my-challenges");
      return;
    }
    if (res?.message) {
      const message = res.message;
      toast.error(message);
    } else {
      toast.error("Error Buying Plan! Please Try Again Later.");
    }
  };

  function handleModalVisible() {
    if (!broker) return toast.error("Please select a broker!");
    if (challenge && balance && platform && wallet) {
      setModalVisible(true);
    }
  }
  const getBalance = async () => {
    const response = await getData("wallet-balance");
    if (response?.status === "success") {
      setWallet(response.balance);
    }
  };

  useEffect(() => {
    if (wallet === null) {
      getBalance();
    }
  }, [wallet]);

  useEffect(() => {
    setChallenge(challenges[data.accountType]);

    const nonInactiveBalance = challenges[
      data.accountType
    ]?.challenge_balances.find((balance) => balance.status !== "inactive");
    setBalance(nonInactiveBalance);
    console.log(nonInactiveBalance);
  }, [data.accountType]);

  const handlePlatformChange = async (data) => {
    setPlatform(data);
    if (data === "MT4") {
      await setBrokers(mt4Brokers);
      await setBroker(null);
    } else if (data === "MT5") {
      await setBrokers(mt5Brokers);
      await setBroker(null);
    }
    setBroker("");
  };

  if (balance != null) {
    balancePrice = balance.balance_phases.filter(
      (item) => item.name === "Evaluation Phase 1"
    )[0];
  }

  return {
    loading,
    modalVisible,
    setModalVisible,
    handleModalVisible,
    challenge,
    balance,
    platform,
    broker,
    wallet,
    balancePrice,
    handlebuy,
    setBalance,
    handlePlatformChange,
    setBroker,
    brokers,
  };
}
