import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {getData, postData} from "../../../utils/fetchData"

export function useChallengeDetails(id){
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState(false);
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    async function getUserData() {
        setLoading(true);
        setError(false);
        const res = await getData(`user-challenge/${id}`);
        setLoading(false);
    
        if (res?.status === "success") {
          setUser(res.user_challenge);
        } else {
          setError(`user with this ID: ${id} was not found!`);
        }
      }
    
      const handleSubmit = async (data) => {
      
        setSubmitLoading(true);
        const res = await postData("challenge-request-active", data);
        setSubmitLoading(false);
    
        if (res?.status === "success") {
          toast.success(res.message || "User challenge activated successfully!");
          handleBack();
          return;
        }
        toast.error("Error updating request!");
      };
    
      const handleBack = () => {
        navigate("/dashboard/admin-challenge-requests");
      };
      
      useEffect(() => {
        getUserData();
      }, []);

      return {
        user,
        handleSubmit,
        loading,
        submitLoading,
        error,
        handleBack
      }
}