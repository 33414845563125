import { useEffect, useState } from "react";
import { getData } from "../../../utils/fetchData";
import { toast } from "react-toastify";

export function useChallengeRequests() {
  const [challengeRequests, setChallengeRequests] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  async function getRequestChallenges() {
    setLoading((prev) => true);
    setError((prev) => false);
    const res = await getData("challenge-requests");
    if (res?.status === "success") {
      if (res.challengeRequests.length === 0) {
        setError((prev) => "Data not found!");
      } else {
        setChallengeRequests(res.challengeRequests);
      }
    } else {
      toast.error(res?.message || "Unexpected error!");
      setError((prev) => "Error fetching data!");
    }

    setLoading((prev) => false);
  }

  useEffect(() => {
    if (challengeRequests == null) {
      getRequestChallenges();
    }
  }, [challengeRequests]);
  
  return {
    challengeRequests,
    error,
    loading,
  };
}
