import { SubmitButton } from "../../components/shared/SubmitButton";
import { useEffect, useState } from "react";
import { WithdrawInitialInput } from "./WithdrawInitialInput";
import { getData } from "../../utils/fetchData";
import { toast } from "react-toastify";
import { CiCreditCard1 } from "react-icons/ci";
import { CiCalculator1 } from "react-icons/ci";
const availableFrom = ["GF Wallet"];

const creditcardIcon = (
  <svg
    className="w-full h-full max-w-[50px] mr-3 absolute left-0"
    width="82"
    height="66"
    viewBox="0 0 82 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80 32.931C80 18.35 80 11.0595 75.4308 6.52973C70.8619 2 63.5077 2 48.8 2H33.2C18.4922 2 11.1383 2 6.56912 6.52973C2 11.0595 2 18.35 2 32.931C2 47.5119 2 54.8028 6.56912 59.3322C11.1383 63.8621 18.4922 63.8621 33.2 63.8621H48.8C63.5077 63.8621 70.8619 63.8621 75.4308 59.3322C77.9782 56.8067 79.1053 53.4232 79.6042 48.3966"
      stroke="#FDFDFD"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M32.9308 49.0688H18.1377"
      stroke="#FDFDFD"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M49.0689 49.0688H42.3447"
      stroke="#FDFDFD"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M2 24.8621H21.5M80 24.8621H37.1"
      stroke="#FDFDFD"
      stroke-width="4"
      stroke-linecap="round"
    />
  </svg>
);

const calcIcon = (
  <svg
    className="w-full h-full max-w-[50px] mr-3 absolute left-0"
    width="81"
    height="81"
    viewBox="0 0 81 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_330_562)">
      <path
        d="M13.5 16.875C13.5 15.0848 14.2112 13.3679 15.477 12.102C16.7429 10.8362 18.4598 10.125 20.25 10.125H60.75C62.5402 10.125 64.2571 10.8362 65.523 12.102C66.7889 13.3679 67.5 15.0848 67.5 16.875V64.125C67.5 65.9152 66.7889 67.6321 65.523 68.898C64.2571 70.1638 62.5402 70.875 60.75 70.875H20.25C18.4598 70.875 16.7429 70.1638 15.477 68.898C14.2112 67.6321 13.5 65.9152 13.5 64.125V16.875Z"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27 27C27 26.1049 27.3556 25.2465 27.9885 24.6135C28.6215 23.9806 29.4799 23.625 30.375 23.625H50.625C51.5201 23.625 52.3786 23.9806 53.0115 24.6135C53.6444 25.2465 54 26.1049 54 27V30.375C54 31.2701 53.6444 32.1285 53.0115 32.7615C52.3786 33.3944 51.5201 33.75 50.625 33.75H30.375C29.4799 33.75 28.6215 33.3944 27.9885 32.7615C27.3556 32.1285 27 31.2701 27 30.375V27Z"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27 47.25V47.2728"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.5 47.25V47.2728"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54 47.25V47.2728"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27 57.375V57.3978"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.5 57.375V57.3978"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54 57.375V57.3978"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_330_562">
        <rect width="81" height="81" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export function InitialInfoForm({ handleSetWithdraw }) {
  const [balance, setBalance] = useState(null);
  const [withdrawFrom, setWithdrawFrom] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [error, setError] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    console.log(balance);
    if (withdrawAmount < 12) {
      setError("Minimum withdraw is $12");
      toast.error("Minimum withdraw is $12");
      return;
    }
    if (withdrawAmount > +balance) {
      setError("Insufficient Funds!");
      toast.error("Insufficient Funds!");
      return;
    }
    handleSetWithdraw({
      amount: withdrawAmount,
      withdrawFrom: withdrawFrom,
      step: 2,
    });
  }

  const getBalance = async () => {
    const response = await getData("wallet-balance");
    console.log(response);
    if (response?.status === "success") {
      console.log("here");
      setBalance(response.balance);
    }
  };

  useEffect(() => {
    if (balance === null) {
      getBalance();
    }
  }, [balance]);

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full grid grid-cols-1 gap-y-2 pl-8 relative my-6"
    >
      <WithdrawInitialInput
        func={setWithdrawFrom}
        value={withdrawFrom}
        // icon={<CiCreditCard1 size={50} className="mr-3 absolute left-0"/>}
        text={"Withdraw from"}
      >
        {availableFrom.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </WithdrawInitialInput>
      <label htmlFor="amount" className="mt-3">Amount:</label>
      <div className="w-full flex items-center justify-start">
      {/* <CiCalculator1 size={50} className="mr-3 absolute left-0" /> */}
        <input
        id="amount"
          value={withdrawAmount}
          required={true}
          onInvalid={(e) => {
            e.preventDefault();
            setError("Please fill the input");
          }}
          placeholder="Please Enter Withdraw Amount Here..."
          onChange={(e) => {
            setWithdrawAmount(e.target.value);
            setError(false);
          }}
          type="number"
          className="w-full max-w-xs bg-transparent border-2 placeholder:text-sm placeholder:opacity-50 text-white text-lg font-normal border-white rounded-lg p-2  focus:outline-0 [&>*]:bg-gray-700 placeholder:text-white"
        />
      </div>
      <span
        className={`text-sm text-rose-600 mb-1 ${error ? "block" : "hidden"}`}
      >
        {error}
      </span>

      <SubmitButton optClass="mt-5"/>
    </form>
  );
}
