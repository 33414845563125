import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getData, postData } from "../utils/fetchData";
import { toast } from "react-toastify";
export function useEmailVerification(){
    const [result, setResult] = useState("");
    const [timer, setTimer] = useState(120);
    const navigate = useNavigate();

    async function sendCode() {
        try {
        const res = await getData("send-otp");
        if (res?.status === "success") {
            const message = res.message;
            toast.success(message);
        } else {
            if (res?.message) {
            const message = res.message;
            toast.error(message);
            }
        }
        } catch (error) {
        toast.error("error sending email, please try later");
        }
    }

    async function verifyOtp(otpData) {
        try {
        const res = await postData("verify-otp", otpData);
        if (res?.status === "success") {
            toast.success(res.message);
            navigate("/dashboard");
        } else {
            if (res?.message) {
            const message = res.message;
            toast.error(message);
            }
        }
        } catch (error) {
        toast.error("error sending email, please try later");
        }
    }

    useEffect(() => {
        sendCode();
    }, []);
    useEffect(() => {
        if (timer > 0) {
        const interval = setInterval(() => {
            setTimer((time) => time - 0.1);
        }, 100);
        return () => clearInterval(interval);
        }
    }, [timer]);

    useEffect(() => {
        if (result.length === 6) {
        const otpData = {
            otp: result,
        };
        verifyOtp(otpData);
        }
    }, [result]);

    const handleOnChange = (res) => {
        // setError((prev) => false);
        setResult((prev) => res);
    };

    async function handleResendEmail() {
        if (timer <= 0) {
        sendCode();
        setTimer(120);
        }
    }
    return {timer, handleOnChange, handleResendEmail}
}