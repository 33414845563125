import { useState} from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { postData } from "../utils/fetchData";
import { authActions } from "../store/slices/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function useLogin() {
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
    role: "trader",
    remember: false,
  });
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => authGoogle(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loginInfo.email && loginInfo.password) {
      setLoading((x) => true);
      try {
        const res = await postData("login", loginInfo);

        if (res?.user) {
          dispatch(
            authActions.loginSuccess({
              token: res.token,
              user: res.user,
              role: res.role,
              permission: res.permissions,
            })
          );

          localStorage.setItem("userData", JSON.stringify(res));

          if (res?.user.email_verified_at == null) {
            navigate("/email-verification");
          } else {
            navigate("/dashboard");
            toast.success("successfully logged in!");
          }
        } else {
          toast.error(res?.message || "Error login!");
        }
      } catch (error) {}

      setLoading((x) => false);
    }
  };

  const authGoogle = async (data) => {
    if (data === null) return;
    setLoading((x) => true);
    const res = await postData("login-google", data);
    if (res?.user) {
      dispatch(
        authActions.loginSuccess({
          token: res.token,
          user: res.user,
          role: res.role,
          permission: res.permissions,
        })
      );
      localStorage.setItem("userData", JSON.stringify(res));

      toast.success("Successfully logged in!");

      if (res?.user.email_verified_at == null) {
        navigate("/email-verification");
        return;
      }

      navigate("/dashboard");
      return;
    }
    toast.error(res?.message || "Error login!");
    setLoading((x) => false);
  };

  function handleSetLoginInfo(e) {
    if (e.target.name === "remember") {
      setLoginInfo((prev) => ({ ...prev, remember: !prev.remember }));
    } else {
      setLoginInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  }

  function handleSetShowPass() {
    setShowPass((prev) => !prev);
  }

  return {
    login,
    loading,
    handleSubmit,
    showPass,
    loginInfo,
    handleSetLoginInfo,
    handleSetShowPass,
  };
}
