import React, { useState } from "react";
import PageStatus from "../../../components/PageStatus/PageStatus";
import Pagination from "../../../components/AdminShared/Pagination";
import Loading from "../../../components/shared/Loading";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/Admin/User/useUser";
import TableBackground from "../../../components/AdminShared/TableBackground";
import Table from "../../../components/AdminShared/Table";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import RequestDetailsButton from "../../../components/AdminShared/RequestsDetailsButton";
const initialFilter = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
};
export default function Users() {
  const [pagination, setPagination] = useState(1);
  const [filter, setFilter] = useState(initialFilter);

  const { error, loading, users, userLinks, setRefresh, setQuery } =
    useUser(pagination);

  function handleSetFilter(e) {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  function handleSubmitFilter(e) {
    e.preventDefault();
    let query;
    if (filter.firstname) {
      query += `&firstname=${filter.firstname}`;
    }
    if (filter.lastname) {
      query += `&lastname=${filter.lastname}`;
    }
    if (filter.email) {
      query += `&email=${filter.email}`;
    }
    if (filter.phone) {
      query += `&phone=${filter.phone}`;
    }

    setQuery(query);
    setRefresh(true);
  }

  function handlePagination(event) {
    setPagination(event.selected + 1);
    setRefresh(true);
  }

  return (
    <>
      <PageStatus
        category={"Users"}
        currentPage={"Users"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        {error ? (
          <ErrorMessage />
        ) : loading ? (
          <div className="w-full flex items-center justify-center h-[700px]">
            <Loading />
          </div>
        ) : (
          <div className="content mx-5 py-5 text-center text-white ">
            <div className=" flex  items-center justify-between title text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">User Index</h3>
            </div>
            <form onSubmit={handleSubmitFilter} className="mb-10 text-end">
              <div className="w-full grid grid-cols-4 gap-3">
                <input
                  name="firstname"
                  placeholder="First Name"
                  onChange={handleSetFilter}
                  value={filter.firstname}
                  type="text"
                  className="input-bordered bg-slate-300 focus:bg-slate-100 text-black input input-sm "
                />
                <input
                  name="lastname"
                  placeholder="Last Name"
                  onChange={handleSetFilter}
                  value={filter.lastname}
                  type="text"
                  className="input-bordered bg-slate-300 focus:bg-slate-100 text-black input input-sm"
                />
                <input
                  name="email"
                  placeholder="Email"
                  onChange={handleSetFilter}
                  value={filter.email}
                  type="text"
                  className="input-bordered bg-slate-300 focus:bg-slate-100 text-black input input-sm"
                />
                <input
                  name="phone"
                  placeholder="Phone"
                  onChange={handleSetFilter}
                  value={filter.phone}
                  type="number"
                  className="input-bordered bg-slate-300 focus:bg-slate-100 text-black input input-sm"
                />
              </div>
              <RequestDetailsButton
                type="submit"
                title="Filter"
                optClass="btn-success mt-3 "
              />
            </form>
            <Table theads={["Id", "Name", "Email", "Role", "Phone", "Actions"]}>
              {users.map((user) => (
                <tr>
                  <td>{user.id}</td>
                  <td>
                    <p>{user.first_name + " " + user.last_name}</p>
                  </td>
                  <td>
                    <p>{user.email}</p>
                  </td>
                  <td>
                    <p>{user.roles[0]?.title}</p>
                  </td>
                  <td>
                    <p>{user.phone}</p>
                  </td>
                  <th>
                    <Link
                      to={`update/${user.id}`}
                      className="btn btn-primary btn-outline  btn-sm mr-2"
                    >
                      Update
                    </Link>
                    <Link
                      to={`add-challenge/${user.id}`}
                      className="btn btn-secondary btn-outline  btn-sm mr-2"
                    >
                      Add Challenge
                    </Link>
                    <Link
                      to={`certificates/${user.id}`}
                      className="btn btn-primary btn-outline  btn-sm mr-2"
                    >
                      Certificates
                    </Link>
                    <Link
                      to={`payments/${user.id}`}
                      className="btn btn-secondary btn-outline  btn-sm mr-2"
                    >
                      Payments
                    </Link>
                  </th>
                </tr>
              ))}
            </Table>
          </div>
        )}
        {users.current ?? (
          <Pagination
            handleOnClick={handlePagination}
            lastPage={userLinks.last_page}
          />
        )}
      </TableBackground>
    </>
  );
}
