import { useState, useEffect } from "react";
import { getData, postData } from "../utils/fetchData";
import { toast } from "react-toastify";
const transferInitialState = {
  from: 1,
  to: 3,
  amount: null,
};

export function useMyWallets(pagination) {
  const [wallet, setWallet] = useState(null);
  const [secondWallet, setSecondWallet] = useState(null);
  const [secondWalletType, setSecondWalletType] = useState(null);
  const [typeName, setTypeName] = useState("all");
  const [transactions, setTransactions] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTransitions, setLoadingTransitions] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [errorTransitions, setErrorTransitions] = useState(false);
  const [transferData, setTransferData] = useState(transferInitialState);
  const [modalVisible, setModalVisible] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (wallet === null) fetchWallet();
  }, [wallet]);

  useEffect(() => {
    fetchTransitions();
  }, [pagination, query]);

  const fetchWallet = async () => {
    setLoading((x) => true);
    const res = await getData("wallet-amount-role");
    setLoading((x) => false);
    if (res?.status === "success") {
      setWallet(res.wallet);
      setSecondWallet(res.balance);
      setSecondWalletType(res.type);
      return;
    }
    setError(true);
    if (res?.message) {
      toast.error(res.message);
    }
    toast.error("Error fetching data!");
  };

  const fetchTransitions = async () => {
    setLoadingTransitions((x) => true);
    const res = await getData(`transactions?page=${pagination}${query}`);
    console.log(res);
    setLoadingTransitions((x) => false);
    if (res?.status === "success") {
      setTransactions(res);
      return;
    }
    setErrorTransitions(true);
    if (res?.message) {
      toast.error(res.message);
    }
    toast.error("Error fetching data!");
  };

  const handlePostTransfer = async () => {
    setModalVisible((prev) => false);
    setLoadingTransfer((prev) => true);
    const response = await postData("transfer-fund", transferData);
    setLoadingTransfer((prev) => false);

    if (response?.status === "success") {
      toast.success("Successfully transferred!");
      fetchTransitions();
      fetchWallet();
      return;
    }

    toast.error(
      response?.message ||
        "Error occurred  during transfer funds, please try again later!"
    );
  };

  function handleSetTypeName(value) {
    setTypeName((prev) => value);
  }

  return {
    loading,
    loadingTransitions,
    loadingTransfer,
    error,
    errorTransitions,
    wallet,
    secondWallet,
    secondWalletType,
    typeName,
    transactions,
    transferData,
    setTransferData,
    handleSetTypeName,
    handlePostTransfer,
    modalVisible,
    setModalVisible,
    setQuery,
  };
}
