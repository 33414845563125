import React from "react";
import { PlanInfo } from "./PlanInfo";
import { ChallengeType } from "./ChallengeType";

export const challengePlans = [
  {
    text: "golden",
    svg: "../goldenPlan.svg",
    type: "golden",
  },
  {
    text: "win-win",
    svg: "../winwinPlan.svg",
    type: "winWin",
  },
  {
    text: "standard",
    svg: "../standardPlan.svg",
    type: "standard",
  },
];

export default function Plans({ data, setData }) {

  return (
    <>
      {data.step === 1 && <ChallengeType setData={setData} />}
      {data.step === 2 && <PlanInfo setData={setData} data={data} />}
      {data.step === 3 && null}
    </>
  );
}
