import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { postData } from "../../../utils/fetchData";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const mt4Brokers = ["Swissquote", "Uniglobe", "HYCM", "Axi"];
const mt5Brokers = ["Uniglobe", "HYCM", "Swissquote", "OANDA"];

const initialFormData = {
  balance_phase: null,
  broker: null,
  platform: null,
  scale_up: 0,
};

const useAddChallenge = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [challenge, setChallenge] = useState(null);
  const [balance, setBalance] = useState(null);
  const [balances, setBalances] = useState(null);
  const [phases, setPhases] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const challengesArray = useSelector((state) => state.challenge);
  const challenges = Object.entries(challengesArray).map(([key, value]) => ({
    ...value,
    type: key,
  }));

  const handleSubmit = async () => {
    const dataToPost = {
      ...formData,
      user_id: id,
    };

    setLoading(true);

    const res = await postData("user-challenges", dataToPost);
    setLoading(false);
    if (res?.status === "success") {
      toast.success("successfully created!");
      navigate("/dashboard/admin/users");
      return;
    }
    toast.error("error creating challenge");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (challenge) {
      const challengeSelected = challenges.find(
        (challengeItem) => challengeItem.id == challenge
      );

      setBalances(
        challengeSelected.challenge_balances.filter(
          (balance) => balance.status === "active"
        )
      );
      setFormData((prev) => ({ ...prev, balance_phase: "" }));
      setPhases(null);
      setBalance("");
    }
  }, [challenge]);

  useEffect(() => {
    if (balance) {
      const balanceSelected = balances.find(
        (balanceItem) => balanceItem.id == balance
      );
      setPhases(
        balanceSelected.balance_phases.filter(
          (balance) => balance.status === true
        )
      );
    }
  }, [balance]);

  return {
    loading,
    balance,
    formData,
    setFormData,
    handleChange,
    setBalance,
    challenges,
    challenge,
    setChallenge,
    phases,
    balances,
    mt4Brokers,
    mt5Brokers,
    handleSubmit,
  };
};

export default useAddChallenge;
