import React, { useState } from "react";
import { IoMdGift } from "react-icons/io";

export default function Bonus({ token, referralCode, referralQrCode }) {
  const [copy, setCopy] = useState(false);
  function handleCopy() {
    navigator.clipboard.writeText(token);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  }
  return (
    <>
      <div className="w-full max-w-4xl flex flex-col justify-between  bg-[var(--indigo-purple-dark)] rounded-xl pb-8 mt-20 md:mt-5">
        <div className="px-5 py-4">
          <p className="font-bold text-lg md:text-2xl text-white">
            %10 bonus from invitees challenges bought
          </p>
          <p className="font-bold text-lg md:text-2xl text-white pt-2">
            %5 fund profit from invitees
          </p>
          <p className="font-semibold text-sm md:text-base text-neutral-300">
            Your referral code : {referralCode}
          </p>
        </div>
        <div className="w-full self-center flex items-center justify-evenly">
          <div className="hidden md:block">
            <img src={referralQrCode} className="max-w-[120px]" alt="" />
            <div
              className={` ${copy ? "tooltip" : ""} w-full`}
              data-tip="copied"
            >
              <button
                onClick={handleCopy}
                className="w-full min-w-20 focus:bg-orange-500 bg-orange-600 transition-colors text-white font-semibold rounded-full py-0.5 mt-3"
              >
                Share Now
              </button>
            </div>
          </div>
          <div className="max-w-lg">
            <img src="../referral-banner.png" alt="" />
          </div>
        </div>
      </div>
      <div className="w-full max-w-4xl h-2 bg-[#ED1C24] relative bottom-2">
        <IoMdGift
          size={70}
          className="fill-[#ED1C24] absolute -bottom-1 right-10 "
        />
        <svg
          className="absolute left-[-10px] top-[-13px]"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4948 32.9775C16.4948 23.8852 22.8571 17.9282 32.7981 16.6741C24.7741 14.6316 18.5373 8.39478 16.4948 0.37075C14.4523 8.39478 8.21543 14.6681 0.191406 16.6741C8.21543 18.7166 14.4523 24.9535 16.4948 32.9775Z"
            fill="#F8CC15"
          />
        </svg>
        <svg
          className="absolute right-[-10px] top-[-13px]"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4948 32.9775C16.4948 23.8852 22.8571 17.9282 32.7981 16.6741C24.7741 14.6316 18.5373 8.39478 16.4948 0.37075C14.4523 8.39478 8.21543 14.6681 0.191406 16.6741C8.21543 18.7166 14.4523 24.9535 16.4948 32.9775Z"
            fill="#F8CC15"
          />
        </svg>
      </div>
    </>
  );
}
