import React, {useState} from "react";
import {Link} from "react-router-dom";
import PageStatus from "../../../components/PageStatus/PageStatus";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import CustomUploadAdapter from "../../../utils/CustomUploadAdapter";
import usePost from "../../../hooks/Blog/Post/usePost";
import TableBackground from "../../../components/AdminShared/TableBackground";
import RequestDetailsForm from "../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {RequestDetailsButtonLayout,} from "../../../components/AdminShared/RequestsDetailsButton";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import "../preview.css";
import RequestsDetailsInputMultiple from "../../../components/AdminShared/RequestsDetailsInputMultiple";
import DropZoneComponent from "../../../components/AdminShared/DropZoneComponent";

const inputStyle = "!input-md !text-xl text-black";

const PostCreate = () => {
  const [formData, setFormData] = useState({
    title: "",
    meta_title: "",
    description: "",
    meta_description: "",
    slug: "",
    img: [],
    content: "",
  });

  const [keywords, setKeywords] = useState([]);

  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader);
    };
  }

  const { loading, error, createPost } = usePost();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLoadImage = (file) => {
    setFormData({ ...formData, ['img']: file });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataPost = new FormData();
    formDataPost.append("title", formData.title);
    formDataPost.append("meta_title", formData.meta_title);
    formDataPost.append("description", formData.description);
    formDataPost.append("meta_description", formData.meta_description);
    formDataPost.append("slug", formData.slug);
    formDataPost.append("img", formData.img[0].file);
    keywords.forEach(keyword => {
      formDataPost.append("keywords[]", keyword);
    });
    formDataPost.append("content", formData.content);

    await createPost(formDataPost);
  };

  return (
    <>
      <PageStatus
        category={"Blog"}
        currentPage={"Create post"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        <div className=" flex items-center justify-between text-center text-white mb-5">
          <h3 className="text-2xl text-center w-full ">Post index</h3>
          <Link to="/dashboard/post" className="btn btn-outline btn-secondary ">
            Back
          </Link>
        </div>

        <RequestDetailsForm>
          <div className="col-span-full grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
            <RequestDetailsInput
              title="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              titleClass="text-xl"
              optClass={inputStyle}
              required
            />
            <RequestDetailsInput
              title="Meta Title"
              name="meta_title"
              value={formData.meta_title}
              onChange={handleChange}
              titleClass="text-xl"
              optClass={inputStyle}
              required
            />
          </div>
          <RequestDetailsInput
            title="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            titleClass="text-xl"
            formClass="row-start-2 col-span-3"
            optClass={inputStyle}
            required
          />
          <RequestDetailsInput
            title="Meta Description"
            name="meta_description"
            value={formData.meta_description}
            onChange={handleChange}
            titleClass="text-xl"
            formClass="row-start-3 col-span-3"
            optClass={inputStyle}
            required
          />
          <RequestDetailsInput
            title="Slug"
            name="slug"
            value={formData.slug}
            onChange={handleChange}
            titleClass="text-xl"
            formClass="row-start-4 col-span-3"
            optClass={inputStyle}
            required
          />

          <RequestsDetailsInputMultiple
              title="keywords"
              name="keywords"
              value={keywords}
              setValue={setKeywords}
              titleClass="text-xl"
              formClass="row-start-5 col-span-3"
              optClass={inputStyle}
              required
          />

          <DropZoneComponent
              title="cover image"
              name="img"
              handleLoadImage={handleLoadImage}
              titleClass="text-xl"
              formClass="row-start-6 col-span-3"
              optClass={inputStyle}
              required/>

          <div className="row-start-7 col-span-full">
            <label className="block text-sm font-medium mb-1" htmlFor="content">
              Content
            </label>
            <CKEditor
              editor={ClassicEditor}
              config={{
                ...editorConfiguration,
                heading: {
                  options: [
                    {
                      model: "paragraph",
                      title: "Paragraph",
                      class: "ck-heading_paragraph",
                    },
                    {
                      model: "heading1",
                      view: "h1",
                      title: "Heading 1",
                      class: "ck-heading_heading1",
                    },
                    {
                      model: "heading2",
                      view: "h2",
                      title: "Heading 2",
                      class: "ck-heading_heading2",
                    },
                    {
                      model: "heading3",
                      view: "h3",
                      title: "Heading 3",
                      class: "ck-heading_heading3",
                    },
                    {
                      model: "heading4",
                      view: "h4",
                      title: "Heading 4",
                      class: "ck-heading_heading4",
                    },
                  ],
                },
              }}
              data={formData.content}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log("datax");
                console.log(data);
                setFormData({ ...formData, content: data });
              }}
            />
          </div>
        </RequestDetailsForm>
        {error && <ErrorMessage text={error} optcClass="mt-3" />}
        <RequestDetailsButtonLayout>
          <RequestDetailsButton
            title={loading ? "Creating..." : "Create"}
            onclick={handleSubmit}
            disabled={loading}
            optClass="btn-success disabled:bg-success/30 disabled:text-neutral-900"
          />
        </RequestDetailsButtonLayout>
      </TableBackground>
      <p className="text-white t mt-10 mb-2 text-xl">preview:</p>
      <div className="box text-white bg-[#222] p-3 ">
        {formData.content && (
          <div dangerouslySetInnerHTML={{ __html: formData.content }} />
        )}
      </div>
    </>
  );
};

export default PostCreate;
