import React from "react";

export function ActivePlansRow({ plans, children }) {
  return (
    <div class="collapse collapse-arrow rounded-none bg-transparent">
      <input type="radio" name="my-accordion-2" />
      <div class="collapse-title text-xl font-medium px-0 after:!right-3 after:!border-white after:!border-b-2 after:!border-r-2">
        {plans}
      </div>
      <div class="collapse-content grid grid-cols-3 gap-x-3">{children}</div>
    </div>
  );
}
