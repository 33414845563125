import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getData } from "../../utils/fetchData";
import useDidMount from "../useDidMount";

const usePost = (pagination) => {
  const [challenges, setChallenges] = useState([]);
  const [challengeLinks, setChallengeLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const didMount = useDidMount(true);
  const [refresh, setRefresh] = useState(false);

  const getChallenges = async () => {
    setLoading(true);
    setError(false);
    const res = await getData("user-challenges?page=" + pagination);
    setLoading(false);
    setRefresh(false);
    if (res?.status === "success") {
      setChallenges(res.user_challenges.data);
      setChallengeLinks(res.user_challenges);
      return;
    }

    setError(true);
    if (res?.message) {
      toast.error(res.message);
      return;
    }
    toast.error("error fetching data");
  };

  useEffect(() => {
    if (
      pagination &&
      ((challenges.length === 0 && didMount) || (refresh && didMount))
    ) {
      getChallenges();
    }
  }, [didMount, pagination, challenges.length, refresh]);

  return {
    error,
    loading,
    challenges,
    challengeLinks,
    setRefresh,
  };
};

export default usePost;
