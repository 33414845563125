import { useState } from "react";
import { useParams } from "react-router-dom";
import { postData } from "../utils/fetchData";
import { toast } from "react-toastify";
import { checkRequirement } from "../pages/Auth/shared/InputRequirment";
import { useNavigate } from "react-router-dom";
export function useResetPassword() {
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const { email } = useParams();

  const [verificationCode, setVerificationCode] = useState({
    value: "",
    error: false,
  });
  const navigate = useNavigate();

  async function verifyOtp(otpData) {
    setLoading((prev) => true);
    const res = await postData("verify-otp-password", otpData);
    setLoading((prev) => false);

    if (res?.status === "success") {
      toast.success(res.message);
      navigate("/login");
    } else {
      const message = res?.message || "Unknown Error!";
      toast.error(message);

      // setVerificationCode((prev) => ({ ...prev, error: true }));
    }
  }

  function handleSubmit() {
    // if (verificationCode.value !== "424242") {
    //   setVerificationCode((prev) => ({ ...prev, error: true }));
    //   return;
    // }
    const requirements = checkRequirement(newPass);
    if (!requirements.every((x) => x === true)) return;
    if (newPass !== confirmPass) {
      setError(true);
      return;
    }
    const verifyData = {
      otp: verificationCode.value,
      password: newPass,
      email: email,
    };

    verifyOtp(verifyData);
    // sendemail
  }
  function handleSetVerification(values) {
    setVerificationCode((prev) => ({ ...prev, ...values }));
  }
  function handleSetConfirmPass(val) {
    setConfirmPass(val);
  }
  function handleSetNewPass(val) {
    setNewPass((prev) => val);
  }
  function handleSetShowPass() {
    setShowPass((prev) => !prev);
  }

  return {
    error,
    loading,
    showPass,
    handleSetShowPass,
    verificationCode,
    email,
    newPass,
    confirmPass,
    handleSubmit,
    handleSetVerification,
    handleSetNewPass,
    handleSetConfirmPass,
  };
}
