import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getData, postDataImge, putData } from "../utils/fetchData";
import { formatErrorsAsString } from "../utils/ErrorHandler";
import { useNavigate } from "react-router-dom";

export function useTicketChat(id, role = "user") {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(false);
  const navigate = useNavigate()

  async function getTicket() {
    let API;
    if (role === "admin") {
      API = "admin-ticket-show/"
    }else{
      API = "ticket/"
    }
    setLoading((prev) => true);
    const res = await getData(API + id);
    setLoading((prev) => false);

    if (res?.ticket) {
      const mainMessage = {
        message: res.ticket.message,
        type: "user",
        created_at: res.ticket.created_at,
      };
      const allMessages = [mainMessage, ...res.ticket.messages];
      setUser(res.ticket.user_id);
      setStatus(res.ticket.status);
      setMessages(allMessages);

      setTitle(res.ticket.title);
    } else {
      toast.error("Ticket Not Found!");
      setError(true);
    }
  }

  const pushMess = async (message) => {
    let API;
    if (role === "admin") {
      API = "admin-ticket-message/"
    }else{
      API = "ticket/send-messange/"
    }

    if (message.message == null) {
      toast.error("Please fill input field!");
      return;
    }
    setNewMessage((prev) => !prev);

    const formData = {
      message: message.message,
    };
    const res = await putData(API + id, formData);
    if (res?.status === "success") {
      setMessages([...messages, res.message]);
    } else {
      toast.error("Error  sending message! Please Try Again Later.");
    }
    setNewMessage((prev) => !prev);
  };

  const pushFile = async (file) => {
    let API;
    if (role === "admin") {
      API = "admin-ticket-img"
    }else{
      API = "ticket-img"
    }

    setNewMessage((prev) => !prev);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("ticket_id", id);
    const res = await postDataImge(API, formData);

    if (res?.message) {
      toast.success("file uploaded Successfully!");
      setMessages([...messages, res.message]);
    } else {
      toast.error(
        res.errors
          ? formatErrorsAsString(res.errors)
          : "Error uploading file! Please Try Again Later.!"
      );
    }
    setNewMessage((prev) => !prev);
  };

   async function handleClose() {
    const res = await getData("admin-ticket-close/" + id);
    if (res?.status === "success") {
      toast.success(res.message);
      navigate(-1);
    } else {
      toast.error("Ticket Not Found!");
      setError(true);
    }
  }

  useEffect(() => {
    if (!error && title === "") {
      getTicket();
    }
  }, [title]);

  return {
    loading,
    error,
    title,
    user,
    status,
    messages,
    newMessage,
    pushMess,
    pushFile,
    setNewMessage,
    handleClose
  };
}
