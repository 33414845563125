import React from "react";
import { FaArrowRight, FaCheck } from "react-icons/fa6";

export function ChallengeType({ setData }) {
  function handleChange(type) {
    setData((prev) => ({ ...prev, accountType: type, step: 2 }));
  }
  return (
    <div className="-mb-32">
      <ChallengeTypeOption
        img="../goldenPlan.svg"
        title="golden"
        type={"golden"}
        func={handleChange}
      />
      <ChallengeTypeOption
        img="../winwinPlan.svg"
        title="win - win"
        type={"winWin"}
        func={handleChange}
      />
      <ChallengeTypeOption
        img="../standardPlan.svg"
        title="standard"
        type={"standard"}
        func={handleChange}
      />
      {/* <img
        src="../newChallengeChoosePlan.png"
        className="max-w-[45%] absolute bottom-[calc(-10%-8rem)] right-0 z-10 hidden md:block"
        alt=""
      />
      <img
        src="../dashboardBgWave.png"
        className="absolute bottom-[-40%] max-h-[700px] w-[80%] left-24 mix-blend-color-burn"
        alt=""
      /> */}
    </div>
  );
}
function ChallengeTypeOption({ img, title, type, func }) {
  return (
    <div
      onClick={() => func(type)}
      className="w-full max-w-sm pr-10 my-4 group z-[50] relative cursor-pointer "
    >
      <div className="w-full relative group-hover:ml-10 transition-all duration-300">
        <div className="w-full flex items-center gap-2 bg-[var(--indigo-purple-dark)] group-hover:bg-[var(--indigo-purple-hover)] transition-all rounded-2xl px-5 py-3 md:py-8 z-10 relative">
          <img src={img} className="max-w-[50px]" alt="" />
          <div className="capitalize text-white">
            <h4 className="text-xl md:text-3xl font-bold">{title}</h4>
            <p className="font-light">Available now</p>
          </div>
        </div>
        <div className="w-20 h-[calc(100%-2px)] bg-white rounded-r-2xl absolute top-[1px] -right-10 overflow-hidden">
          <div className="group-hover:left-10 left-0 duration-300 transition-all relative flex flex-row-reverse items-center justify-between p-1 w-full h-full">
            <FaArrowRight
              size={30}
              className="fill-[var(--indigo-purple-dark)] "
            />
            <FaCheck size={30} className="fill-[var(--indigo-purple-hover)] " />
          </div>
        </div>
      </div>
    </div>
  );
}
