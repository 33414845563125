import {useEffect, useRef, useState} from 'react';
import { toast } from "react-toastify";
import { postData, postDataImge} from "../utils/fetchData";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../store/slices/auth";

const initialFormData = {
    first_name: "",
    last_name: "",
    birth: "",
    // Add more input fields here
}

const useCertificate = () => {
  const [loading, setLoading]               = useState(false);
  const [formData, setFormData]             = useState(initialFormData);
  const [selectedImage, setSelectedImage]   = useState(null);
  const fileInputRef                        = useRef(null);
  const [showFile, setShowFile]             = useState(false);
  const user        = useSelector((state) => state.auth.user);
  const dispatch    = useDispatch();

    useEffect(() => {
        setFormData({
            first_name: user.first_name,
            last_name: user.last_name,
            birth: user.birth ? user.birth.split(" ")[0] : null,
        });
    }, []);

    useEffect(() => {
        if (user.photo == null) {
            setSelectedImage("../UserProfilePhoto.png");
        } else {
            setSelectedImage(
                process.env.REACT_APP_API_BASE_URL + "/storage/" + user.photo
            );
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleReset = () => {
        setFormData({
            first_name: user.first_name,
            last_name: user.last_name,
            birth: user.birth ? user.birth.split(" ")[0] : null,
        });
        toast("Reset successful!");
    };

    const handleSubmit = async () => {
        postProfile();
    };


    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(URL.createObjectURL(file));
        setShowFile(true);
    };

    const handleUploadImage = async () => {
        postProfileImage();
    };

    const postProfile = async () => {
        setLoading((prev) => true);
        const res = await postData("profile", formData);

        if (res?.status === "success") {
            await dispatch(authActions.setUser(res.user));
            toast.success("Updated Successfully!");
        } else {
            toast.error("Error  Updating! Please Try Again Later.");
        }
        setLoading((prev) => false);
    };

    const postProfileImage = async () => {
        setLoading((prev) => true);
        const formData = new FormData();
        formData.append("photo", fileInputRef.current.files[0]);

            const res = await postDataImge("profile-img", formData);

            if (res?.status === "success") {
                setShowFile(false);
                await dispatch(authActions.setUser(res.user));

                toast.success("Updated Successfully!", { toastId: 1 });
            } else {
                toast.error("Error  Updating! Please Try Again Later.");
            }
        setLoading((prev) => false);
    };

    return {
       loading, user, formData, selectedImage, showFile, fileInputRef, handleInputChange, handleReset, handleSubmit, handleButtonClick,
        handleImageChange, handleUploadImage
  };
};

export default useCertificate;
