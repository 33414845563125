import React, { useRef, useState } from "react";
import { postDataImge } from "../../../utils/fetchData";
import { toast } from "react-toastify";
import Loading from "../../../components/shared/Loading";

const BannerCreate = ({ setType, getBanners }) => {
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(false);

  const postProfileImage = async () => {
    const formData = new FormData();
    formData.append("photo", fileInputRef.current.files[0]);
    formData.append("title", title);
    formData.append("status", "active");
    setLoading(true);
    const res = await postDataImge("banners", formData);

    setLoading(false);
    if (res?.status === "success") {
      toast.success("banner created successfully");
      getBanners();
      setType("index");
      return;
    }
    toast.error(res?.message);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
  };

  return loading ? (
    <div className="w-full text-center">
      <Loading />
    </div>
  ) : (
    <>
      <div className="flex items-center justify-between text-center">
        <h3 className="w-full text-2xl text-white">Banner create</h3>
        <button
          onClick={() => setType("index")}
          className="btn btn-secondary btn-outline"
        >
          back
        </button>
      </div>
      <div>
        <form>
          <div className="mb-4 w-1/3">
            <label
              className="block text-xl font-medium mb-1 text-white"
              htmlFor="title"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              className="w-full px-3 py-2 border rounded input-bordered  bg-[#070F2B] text-white"
            />
          </div>
          <div>
            <button
              type="button"
              onClick={handleButtonClick}
              className="w-full max-w-[200px] bg-primaryPurple outline-none border-none text-xl text-white  p-2"
            >
              Choose Image
            </button>

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
          <div>
            <img
              src={selectedImage}
              className="max-w-[250px] sm:max-w-[400px] m-auto"
              alt=""
            />
          </div>
        </form>
      </div>

      <button
        onClick={postProfileImage}
        className="btn btn-outline btn-success mt-10"
      >
        Create
      </button>
    </>
  );
};

export default BannerCreate;
