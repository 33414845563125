import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { getData } from "../utils/fetchData";
import useDidMount from './useDidMount';

const useCertificate = () => {
  const [achievementCertificates, setAchievementCertificates]   = useState(null);
  const [profitCertificates, setProfitCertificates]             = useState(null);
  const [loading, setLoading]                                   = useState(false);
  const [error, setError]                                       = useState(false);
  const didMount                                                = useDidMount(true);

  const getCertificates = async () => {
      setLoading((prev) => true);
      setError((prev) =>false);
      const res = await getData("certificate");
      setLoading((prev) =>false);
      if (res?.status === 'success') {
          setAchievementCertificates(res.achievement_certificates);
          setProfitCertificates(res.profit_certificates);
        return;
      }
      setError((prev) =>true);
      if(res?.message){
        toast.error(res.message);
        return;
      }
      toast.error('error fetching data');
    };

  useEffect(() => {
    if ((achievementCertificates === null) && didMount) {
      getCertificates();
    }
  }, [achievementCertificates, profitCertificates]);
  return {
      achievementCertificates, profitCertificates, loading, error
  };
};

export default useCertificate;
