export default class ChallengeStatistics {
    constructor(data, dataChallenge, dataArchive) {
        this.data = data;
        this.dataChallenge = dataChallenge;
        this.dataArchive = dataArchive;
    }

    calculateLoss() {
        let loss = ((this.data.equity - this.dataChallenge.balance) > 0) ? 0 : (this.data.equity - this.dataChallenge.balance);
        loss = Math.abs(loss.toFixed(2));
        return loss;
    }

    calculateDailyLoss() {
        let dailyLoss = ((this.data.equity - this.dataArchive.balance) > 0) ? 0 : (this.data.equity - this.dataArchive.balance);
        dailyLoss = Math.abs(dailyLoss.toFixed(2));
        return dailyLoss;
    }

    calculateDaysAvailable() {
        const totalDays         = this.dataChallenge.challenge_period;
        const currentDate       = new Date();
        const startTime         = new Date(this.dataChallenge.created_at);
        const timeDifference    = (currentDate - startTime);
        const daysGone          = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        let daysAvailable       = totalDays - daysGone;
        daysAvailable           = (daysAvailable > 30) ? 30 : daysAvailable;
        daysAvailable           = (daysAvailable < 0) ? 0 : daysAvailable;
        daysAvailable           = (this.dataChallenge.challenge !== null) ? daysAvailable : 30;
        return daysAvailable;
    }

    getCenterValueTime() {
        const daysAvailable = this.calculateDaysAvailable();
        return (this.dataChallenge.challenge_period !== null) ? daysAvailable : '∞';
    }

    getTitleValueTime() {
        return (this.dataChallenge.challenge_period !== null) ? '30Days' : '∞';
    }

    getAllStatistics() {
        return {
            loss:               this.calculateLoss(),
            dailyLoss:          this.calculateDailyLoss(),
            daysAvailable:      this.calculateDaysAvailable(),
            centerValueTime:    this.getCenterValueTime(),
            titleValueTime:     this.getTitleValueTime()
        };
    }
}