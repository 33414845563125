import { useState, useEffect } from "react";
import { getData } from "../utils/fetchData";
import { useParams } from "react-router-dom";
import {toast} from "react-toastify";
export function useAnalyze(){
    const [loading, setLoading] = useState(true);
    const [tadeLoading, setTradeLoading] = useState(true);
    const [totals, setTotals] = useState(null);
    const [totalsArchive, setTotalsArchive] = useState(null);
    const [totalArchives, setTotalArchives] = useState(null);
    const [trades, setTrades] = useState(null);
    const [thisChallenge, setThisChallenge] = useState(null);
    
  
    const { id } = useParams();
  
    console.log(id);

    const getUserChallenge = async(id) => {
        const res = await getData("user-challenge/"+id);
        if (res?.status === 'success') {
            setThisChallenge(res.user_challenge);
            return;
        }
        toast.error('error fetching data');
    }
  
    async function getUserInformation() {
      try {
        const res = await getData(
          "user-challenge-account-information-update/" + id
        );
        if (res?.totals) {
          setTotals(res.totals[0]);
        }
      } catch (error) {
        return null;
      }
    }
  
    async function getUserInformationArchive() {
      try {
        const res = await getData(
          "user-challenge-account-information-archive/" + id
        );
        if (res?.total_archives) {
          setTotalsArchive(res.total_archives[res.total_archives.length - 1]);
          setTotalArchives(res.total_archives);
        }
      } catch (error) {
        return null;
      }
    }
  
    async function getUserTrades() {
      try {
        const res = await getData("user-challenge-trades-update/" + id);
        if (res?.trades) {
          setTrades(res.trades);
        }
      } catch (error) {
        return null;
      }
    }

    useEffect(() => {
        if (thisChallenge == null) {
            getUserChallenge(id);
        }
    }, [thisChallenge, id]);

    useEffect(() => {
      if (totals == null) {
        getUserInformation();
      }
    }, [totals]);
  
    useEffect(() => {
      if (totalsArchive == null) {
        getUserInformationArchive();
      }
    }, [totalsArchive]);
  
    useEffect(() => {
      setTradeLoading((x) => true);
      if (trades == null) {
        getUserTrades();
      } else {
        setTradeLoading((x) => false);
      }
    }, [trades]);
  
    useEffect(() => {
      if (totals != null && totalsArchive != null && thisChallenge != null) {
        setLoading((x) => false);
      }
    }, [totals, totalsArchive, thisChallenge]);
    
    return {loading, totals, trades, totalsArchive, totalArchives, thisChallenge, tadeLoading} 
}