import { useState, useEffect } from "react";
import { getData, postData, postDataImge } from "../utils/fetchData";
import { toast } from "react-toastify";
import { authActions } from "../store/slices/auth";
import { useDispatch } from "react-redux";
export function useVerification() {
  const [data, setData] = useState({
    policyLive: null,
    phoneNumber: null,
    phoneVerify: null,
    country: null,
    docType: null,
    step: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [verifyState, setVerifyState] = useState(null);
  const [currentPage, setCurrentPage] = useState("policy");
  const [declineReason, setDeclineReason] = useState("");
  const [identityLoading, setIdentityLoading] = useState(false);
  const dispatch = useDispatch();

  const getVerificationState = async () => {
    const response = await getData("get-verification-state");
    setLoading((prev) => false);
    if (response.status === "success") {
      setVerifyState(response.verification_type);
      if (response.verification_type === 5) {
        setDeclineReason(response.decline_reason);
      }
      return;
    }
    toast.error(response.message || "error getting data!");
    setError(true);
  };

  useEffect(() => {
    if (error === false && verifyState === null) {
      setLoading(true);
      getVerificationState();
    }
  }, [error, verifyState]);

  const sendSms = async (number) => {
    const formData = {
      phone: number,
    };

    const response = await postData("send-sms", formData);

    if (response.status === "success") {
      toast.success(response.message);
      setData((prevData) => ({ ...prevData, step: 1 }));
      return;
    }
    toast.error(response.message || "unknown error!");
  };

  const verifySms = async (result) => {
    const formData = {
      phone: data.phoneNumber,
      code: result,
    };

    const response = await postData("verify-sms", formData);

    if (response.status === "success") {
      dispatch(
        authActions.setVerification({
          type: 2,
        })
      );

      toast.success(response.message);
      setCurrentPage("document");
      return 1;
    }
    toast.error(response.message || "unknown error!");
    return 0;
  };

  const handleUploadIdentity = async (file) => {
    if (!data.docType || !data.country) {
      return;
    }
    setIdentityLoading(true);
    const formData = new FormData();
    formData.append("identity", file);
    formData.append("identity_type", data.docType);
    formData.append("country", data.country);
    const res = await postDataImge("identity-verify", formData);
    setIdentityLoading(false);
    if (res?.status === "success") {
      dispatch(
        authActions.setVerification({
          type: 3,
        })
      );
      handleCurrentPage("uploaded");
      toast.success(res.message);
      return;
    }

    toast.error("Error uploading file!");
  };

  useEffect(() => {
    if (verifyState === 2) {
      setCurrentPage("document");
    }
    if (verifyState === 3) {
      setCurrentPage("uploaded");
    }
    if (verifyState === 4) {
      setCurrentPage("success");
    }
    if (verifyState === 5) {
      setCurrentPage("declined");
    }
  }, [verifyState]);

  function handleSetData(property, value) {
    setData((prev) => ({ ...prev, [property]: value }));
  }
  function handleCurrentPage(property) {
    setCurrentPage(property);
  }
  function uploadAgain() {
    setCurrentPage("document");
  }

  return {
    error,
    loading,
    data,
    currentPage,
    sendSms,
    verifySms,
    handleSetData,
    handleCurrentPage,
    declineReason,
    uploadAgain,
    handleUploadIdentity,
    identityLoading,
  };
}
