import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getData } from "../utils/fetchData";
import { toast } from "react-toastify";

const initialReferralBalances = {
    "balance" :         null,
    "profitShareSum":   null,
    "fundShareSum":     null,
}

export function useReferrals(){
    const user                                      = useSelector((state) => state.auth.user);
    const [referralBalances, setReferralBalances]   = useState(initialReferralBalances);
    const [loading, setLoading]                     = useState(false);
    const [error, setError]                         = useState(false);
  
  
    const getBalances = async () => {
      setLoading((prev) => true);
      const response = await getData('wallet-referral-balance');
      setLoading((prev) => false);
      if(response?.status === 'success')
        {
            setReferralBalances(
                {
                    "balance" :         response.balance,
                    "profitShareSum":   response.profit_share_sum,
                    "fundShareSum":     response.fund_share_sum
                }
            );
            return;
        }
      toast.error('Error fetching data!');
      setError((prev) => true);
    }
  
    const handleBalance = async() => {
      setLoading((prev) => true);
      const response = await getData('deposit-referral-balance');
      setLoading((prev) => false);
      if(response?.status === 'success')
        {
          toast.success(response?.message);
          return;
        }
        toast.error(response?.message || "Error fetching data!");
    }
  
    useEffect(() => 
      {
        if(referralBalances.balance === null)
          {
            getBalances();
          }
      }, [referralBalances]);




    return {loading, error, user, referralBalances, handleBalance}
}