import { toast } from "react-toastify";
import { formatErrorsAsString } from "../utils/ErrorHandler";
import { getData, postDataImge } from "../utils/fetchData";
import { useEffect, useState } from "react";

export function useNewTicket() {
  const [departments, setDepartments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  async function getDepartments() {
    setLoading(true);
    const res = await getData("ticket-departments");
    setLoading(false);
    if (res?.departments) {
      setDepartments(res.departments);
    } else {
      toast.error("Error fetching departments!");
    }
  }
  useEffect(() => {
    if (departments == null) {
      getDepartments();
    }
  }, [departments]);

  async function handleUploadTicket(formData, modalClose, newTicketSuccess) {
    setSubmitLoading(true);
    const res = await postDataImge("ticket", formData);
    setSubmitLoading(false);
    if (res?.title) {
      modalClose.current.click();
      newTicketSuccess();
      toast.success("Ticket Sent Successfully!");
    } else {
      toast.error(
        res?.errors
          ? formatErrorsAsString(res.errors)
          : "Error sending ticket try again later!"
      );
    }
  }

  return { loading, submitLoading, handleUploadTicket, departments };
}
