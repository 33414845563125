import React, { useEffect, useState } from "react";
import Loading from "../../components/shared/Loading";
import { getData } from "../../utils/fetchData";
import Table from "../../components/Table";

const headers = [
  "plan summary",
  "account type",
  "start date",
  "end date",
  "permittable loss",
];

export default function PlanTable() {
  const [plans, setPlans] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      const res = await getData("dashboard-plans");
      console.log(res);
      if (res?.dashboard_plans) {
        console.log("here2");
        setPlans(res.dashboard_plans);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    setLoading((x) => false);
  }, []);

  return (
    <>
      {loading || plans === null ? (
        <Loading optClass={"block mx-auto mt-20"} />
      ) : (
        plans.length > 0 && (
          <Table headers={headers}>
            {plans.map((row) => (
              <tr>
                <td>{row.id}</td>
                <td>{row.plan_summary}</td>
                <td>{row.account_type}</td>
                <td>{row.start_date ?? "-"}</td>
                <td>{row.end_date ?? "-"}</td>
                <td>{row.permittable_loss}</td>
              </tr>
            ))}
          </Table>
        )
      )}
    </>
  );
}
