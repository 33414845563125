import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PageStatus from "../../../components/PageStatus/PageStatus";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomUploadAdapter from "../../../utils/CustomUploadAdapter";
import usePost from "../../../hooks/Blog/Post/usePost";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../components/AdminShared/RequestsDetailsButton";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import TableBackground from "../../../components/AdminShared/TableBackground";
import RequestDetailsForm from "../../../components/AdminShared/RequestDetailsForm";
import Loading from "../../../components/shared/Loading";
import "../preview.css";
import RequestsDetailsInputMultiple from "../../../components/AdminShared/RequestsDetailsInputMultiple";
import useUpdateImage from "../../../hooks/Shared/useUpdateImage";
import DropZoneUpdateComponent from "../../../components/AdminShared/DropZoneUpdateComponent";

const inputStyle = "!input-md !text-xl text-black";
const PostUpdate = () => {
  const [formData, setFormData] = useState({
    title: "",
    meta_title: "",
    description: "",
    meta_description: "",
    slug: "",
    img:"",
    content: "",
  });

  const [keywords, setKeywords] = useState([]);

  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader);
    };
  }
  const { id } = useParams();

  const { loading, error, updatePost, post } = usePost(null, id);

  const {loading: loadingImage, error: errorImage,  postImage } = useUpdateImage();

  useEffect(() => {
    if (post && post.title !== null && post.title !== "") {
      setFormData({
        title: post.title,
        meta_title: post.meta_title,
        description: post.description,
        meta_description: post.meta_description,
        slug: post.slug,
        img: post.img,
        content: post.content,
      });
      setKeywords(post.keywords? post.keywords : []);
    }
  }, [post?.title, formData.title]);

  console.log(formData);
  console.log(post);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdateImage = async (img) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("img", img[0]);

    await postImage(formData,'blog/post-cover-image');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      keywords,
    };
    await updatePost(data, id);
  };

  return (
    <>
      <PageStatus
        category={"Blog"}
        currentPage={"Update post"}
        logoStarColor="#F8CC15"
      />
      <div className="bg-[var(--sidebar-background)] rounded-xl max-w-6xl mx-auto p-4">
        {error ? (
          <ErrorMessage />
        ) : (
          <TableBackground>
            <div className=" flex items-center justify-between text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">Post index</h3>
              <Link
                to="/dashboard/post"
                className="btn btn-outline btn-secondary "
              >
                Back
              </Link>
            </div>
            {loading ? (
              <div className="flex items-center justify-center h-[620px]">
                <Loading />
              </div>
            ) : (
              <RequestDetailsForm>
                <div className="col-span-full grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
                  <RequestDetailsInput
                    title="Title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    titleClass="text-xl"
                    optClass={inputStyle}
                    required
                  />
                  <RequestDetailsInput
                    title="Meta Title"
                    name="meta_title"
                    value={formData.meta_title}
                    onChange={handleChange}
                    titleClass="text-xl"
                    optClass={inputStyle}
                    required
                  />
                </div>
                <RequestDetailsInput
                  title="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  titleClass="text-xl"
                  formClass="row-start-2 col-span-3"
                  optClass={inputStyle}
                  required
                />
                <RequestDetailsInput
                  title="Meta Description"
                  name="meta_description"
                  value={formData.meta_description}
                  onChange={handleChange}
                  titleClass="text-xl"
                  formClass="row-start-3 col-span-3"
                  optClass={inputStyle}
                  required
                />
                <RequestDetailsInput
                  title="Slug"
                  name="slug"
                  value={formData.slug}
                  onChange={handleChange}
                  titleClass="text-xl"
                  formClass="row-start-4 col-span-3"
                  optClass={inputStyle}
                  required
                />

                <RequestsDetailsInputMultiple
                    title="keywords"
                    name="keywords"
                    value={keywords}
                    setValue={setKeywords}
                    titleClass="text-xl"
                    formClass="row-start-5 col-span-3"
                    optClass={inputStyle}
                    required
                />

                <div className="row-start-6 col-span-full">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="content"
                  >
                    Content
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      ...editorConfiguration,
                      heading: {
                        options: [
                          {
                            model: "paragraph",
                            title: "Paragraph",
                            class: "ck-heading_paragraph",
                          },
                          {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "ck-heading_heading1",
                          },
                          {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2",
                          },
                          {
                            model: "heading3",
                            view: "h3",
                            title: "Heading 3",
                            class: "ck-heading_heading3",
                          },
                          {
                            model: "heading4",
                            view: "h4",
                            title: "Heading 4",
                            class: "ck-heading_heading4",
                          },
                        ],
                      },
                    }}
                    data={formData.content}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFormData({ ...formData, content: data });
                    }}
                  />
                </div>
              </RequestDetailsForm>
            )}
            {error && <ErrorMessage text={error} optcClass="mt-3" />}
            <RequestDetailsButtonLayout>
              <RequestDetailsButton
                title={loading ? "Updating..." : "Update"}
                onclick={handleSubmit}
                disabled={loading}
                optClass="btn-success disabled:bg-success/30 disabled:text-neutral-900"
              />
            </RequestDetailsButtonLayout>
          </TableBackground>
        )}
        <DropZoneUpdateComponent
          serverImage={formData.img}
          handleUpdateImage={handleUpdateImage}
          loading={loadingImage}
        />

        <p className="text-white t mt-10 mb-2 text-xl">preview:</p>
        <div className="box text-white bg-[#222] p-3 ">
          {formData.content && (
            <div dangerouslySetInnerHTML={{ __html: formData.content }} />
          )}
        </div>
      </div>
    </>
  );
};

export default PostUpdate;
