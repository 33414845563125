import { useState, useEffect } from "react";
import { getData } from "../utils/fetchData";
import { toast } from "react-toastify";
export function useTicket(pagination, initialApi = "ticket") {
  const [tickets, setTickets] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [query, setQuery] = useState("");
  const [type, setType] = useState(null);

  function newTicketSuccess() {
    getTickets();
  }

  async function getTickets() {
    let api;
    if (type) {
      switch (type) {
        case "admin-unAssigned":
          api = "admin-ticket-unassigned";
          break;
        case "admin-assigned":
          api = "admin-ticket";
          break;
        default:
          api = "ticket";
          break;
      }
    } else {
      api = initialApi;
    }

    setLoading(true);
    const res = await getData(`${api}?page=${pagination}${query}`);
    setLoading(false);
    if (res?.status === "success") {
      setTickets(res);
    } else {
      toast.error(res?.message || "Ticket Not Found!");
      setError(true);
    }
  }

  useEffect(() => {
    getTickets();
  }, [pagination, query, type]);

  return {
    loading,
    tickets,
    error,
    newTicketSuccess,
    getTickets,
    setQuery,
    setType,
  };
}
