import React, { useState } from "react";
import PlanStepIndicator, { IndicatorItem } from "./PlanStepIndicator";
import Plans from "./Plans";
import PageStatus from "../../components/PageStatus/PageStatus";

const tempData = {
  step: 1,
  accountType: "golden",
  accountBalance: "5000",
};

export default function NewChallenge() {
  const [data, setData] = useState(tempData);
  return (
    <div className="relative">
      <div className="block pb-5">
        <PageStatus
          category={"challenges"}
          currentPage={"New Challenge"}
          pageStarColor={"#11092E"}
          logoStarColor="#ebc216"
        />
        <PlanStepIndicator headerText={"select your plan"}>
          <IndicatorItem
            num={1}
            text={"select challenge type"}
            currentStep={data.step}
            setData={setData}
          />
          <IndicatorItem
            num={2}
            text={"pay"}
            currentStep={data.step}
            setData={setData}
          />
          <IndicatorItem
            num={3}
            text={`show your \n best`}
            last={true}
            currentStep={data.step}
            setData={setData}
          />
        </PlanStepIndicator>
        <Plans data={data} setData={setData} />
      </div>
    </div>
  );
}
