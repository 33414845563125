import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getData, putData } from "../../utils/fetchData";
const initialFormData = {
  total: null,
  win_win: null,
  golden: null,
  standard: null,
};
export function usePlanInfo() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const getStats = async () => {
    setLoading((prev) => true);
    setError((prev) => false);

    const res = await getData("dashboard-info");
    if (res?.status === "success") {
      setFormData(res.dashboard_info_plan_Summary);
    } else {
      setError((prev) => "Error fetching data!");
      toast.error(res?.message || "Unexpected error!");
    }

    setLoading((prev) => false);
  };

  const handleUpdate = async () => {
    setLoading((prev) => true);

    const res = await putData("dashboard-info/" + 1, formData);
    if (res?.status === "success") {
      getStats();
      toast.success("successfully updated!");
    } else {
      setLoading((prev) => false);
      toast.error(res?.message || "Unexpected error!");
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return { loading, error, formData, setFormData, handleUpdate };
}
