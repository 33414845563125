import { createSlice } from '@reduxjs/toolkit';
// import {getData} from '../../utils/fetchData'
import { getToken } from '../../utils/getToken';
import { authActions } from './auth';
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// ----------------------------------------------------------------------

const initialState = {
    topTraders: [],
    maximumWithdraws: [],
    goldenPlanTopTrader: []
  };
  
  const topTraderReducer = createSlice({
    name: 'topTrader',
    initialState,
    reducers: {
      topTrader(state, action) {
        state.topTraders = action.payload;
      },
      maximumWithdraw(state, action) {
        state.maximumWithdraws = action.payload;
      },
      goldenPlanTopTrader(state, action) {
        state.goldenPlanTopTrader = action.payload;
    }
    }
  });
  
  export const topTraderActions = topTraderReducer.actions;
  export default topTraderReducer;

  export  function  getToptraders() {
    return async (dispatch) => {
      let response;
      const userToken = getToken()
    try {
      let token = "Bearer " + userToken;
      response = await fetch(`${baseUrl}/api/top-trader`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (!response.ok) {
        console.log("Failed to fetch data");
        if (response && response.status === 401) {
          await dispatch(authActions.logout())
        }
      }
  
      const jsonData = await response.json();
      if (jsonData.top_traders) {
        const sortedTraders = jsonData.top_traders.sort((a, b) => a.rank - b.rank);
        dispatch(topTraderActions.topTrader(sortedTraders));
    }
    } catch (error) {
      if (response && response.status === 401) {
        await dispatch(authActions.logout())
  
      }
  
      return null;
    }
      
      
  
}
  }

  export  function  getGoldenPlanTopTrader() {
    return async (dispatch) => {
      let response;
    const userToken = getToken()
  try {
    let token = "Bearer " + userToken;
    response = await fetch(`${baseUrl}/api/golden-top-trader`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!response.ok) {
      console.log("Failed to fetch data");
      if (response && response.status === 401) {
        await dispatch(authActions.logout())
      }
    }

    const jsonData = await response.json();
    if (jsonData.golden_plan_top_traders) {
      const sortedTraders = jsonData.golden_plan_top_traders.sort((a, b) => a.rank - b.rank);
      dispatch(topTraderActions.goldenPlanTopTrader(sortedTraders));
  }
  } catch (error) {
    if (response && response.status === 401) {
      await dispatch(authActions.logout())

    }

    return null;
  }
}
  }

  export  function  getMaximumWithdraw() {
    return async (dispatch) => {
      let response;
    const userToken = getToken()
  try {
    let token = "Bearer " + userToken;
    response = await fetch(`${baseUrl}/api/maximum-withdraw`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!response.ok) {
      console.log("Failed to fetch data");
      if (response && response.status === 401) {
        await dispatch(authActions.logout())
      }
    }

    const jsonData = await response.json();
    if (jsonData.maximum_withdraws) {
      const sortedTraders = jsonData.maximum_withdraws.sort((a, b) => a.rank - b.rank);
      dispatch(topTraderActions.maximumWithdraw(sortedTraders));
  }
  } catch (error) {
    if (response && response.status === 401) {
      await dispatch(authActions.logout())

    }

    return null;
    }
  }
}




