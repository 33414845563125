import React from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export function RowTitles({ data }) {
  const navigate = useNavigate();

  return (
    <ul className="grid grid-cols-7 justify-items-center text-center  text-base text-white font-semibold capitalize px-5">
      <li className="flex flex-col items-center w-full">
        <span className="bg-green-500 rounded-md px-2 w-full">{data.status} </span>
        <span className="font-normal text-sm mt-1 text-red-600">
          ({data.phase_type_name})
        </span>
      </li>
      <li className="font-light">${data.balance}</li>
      <li className="font-light capitalize">{data.challenge_type_name}</li>
      <li>
        {data.scale_up_percentage ? (
          <IoMdCheckmarkCircleOutline size={25} className="fill-green-500" />
        ) : (
          <IoMdCloseCircleOutline size={25} className="fill-red-500" />
        )}
      </li>
      <li className="font-light">{data.start_time}</li>
      <li className="flex flex-col items-center ">
        <span className="bg-green-500 rounded-md px-2 font-light pt-1">{data.end_time} </span>
        <span className="font-normal text-sm mt-1 text-red-600">
          ({data.endRemaining}Days remainin)
        </span>
      </li>
      <li className="w-full max-w-[90%] h-fit bg-amber-500 text-primaryPurple font-bold pt-0.5 rounded-md z-50">
        <button onClick={() => navigate("analyze/"+data.id)}>Analyze</button>
      </li>
    </ul>
  );
}
