import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getData } from "../../utils/fetchData";
import CheckoutForm from "./CheckoutForm";
import Loading from "../../components/shared/Loading";
import ErrorMessage from "../../components/shared/ErrorMessage";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const appearance = {
  theme: "night",
  labels: "floating",
  variables: {
    colorPrimary: "#4743e0",
    tabIconColor: "#ff0000",
  },
};
export function DepositPay({ depositData }) {
  const [clientSecret, setClinetSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMess, setError] = useState(false);

  async function fetchSecretKey() {
    try {
      setLoading(true);
      setError((prev) => false);
      const res = await getData("deposit?amount=" + depositData.depositAmount);
      if (res?.clientSecret) {
        setClinetSecret(res.clientSecret);
      } else {
        setError((prev) => true);
      }
    } catch (error) {
      setError((prev) => true);
      return null;
    }
    setLoading((prev) => false);
  }

  useEffect(() => {
    fetchSecretKey();
  }, []);

  function handleFetchSecretKey() {
    fetchSecretKey();
  }

  return (
    <div className="w-full max-w-lg flex items-center justify-center">
      {loading ? (
        <Loading />
      ) : errorMess ? (
        <ErrorMessage optcClass="justify-center" text={`Error fetching data!`}>
          <button
            onClick={handleFetchSecretKey}
            className="-mb-2 underline decoration-warning/60 hover:text-indigo-400 hover:decoration-indigo-500 underline-offset-2 transition-colors"
          >
            please try again.
          </button>
        </ErrorMessage>
      ) : (
        stripePromise &&
        clientSecret && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, appearance }}
          >
            <CheckoutForm />
          </Elements>
        )
      )}
    </div>
  );
}
