import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getData } from "../../utils/fetchData";
export function useSucccessChallenges() {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  async function getSuccessChallenges() {
    setLoading((prev) => true);
    const res = await getData("user-challenges-accept-index");
    if (res?.status === "success") {
      setChallenges(res.user_challenges);
      if (res.user_challenges.length === 0) {
        setError((prev) => true);
      }
    } else {
      toast.error("erro reaching data!");
      setError((prev) => true);
    }
    setLoading((prev) => false);
  }

  const handleSubmit = async (id) => {
    const res = await getData("user-challenges-accept/" + id);
    if (res?.status === "success") {
      toast.success("successfully confimed");
      setChallenges([]);
    } else {
      toast.error("erro reaching data!");
    }
  };

  useEffect(() => {
    if (!error && challenges.length === 0) {
      getSuccessChallenges();
    }
  }, [challenges]);

  return { loading, error, challenges, handleSubmit };
}
