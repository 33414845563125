import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getData, putData } from "../../../utils/fetchData";
import useDidMount from "../../useDidMount";

const useUser = (pagination, userId) => {
  const [user, setUser] = useState(null);
  const [verificationTypes, setVerificationTypes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [userLinks, setUserLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const didMount = useDidMount(true);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState("");
  const getUser = async (id) => {
    setLoading(true);
    setError(false);
    const res = await getData("user/" + id);
    setLoading(false);
    if (res?.status === "success") {
      setUser(res.user);
      setVerificationTypes(res.verification_types);
      setRoles(res.roles);
      return;
    }

    setError(true);
    if (res.message) {
      toast.error(res.message);
      return;
    }
    toast.error("error fetching data");
  };

  const getUsers = async () => {
    setLoading(true);
    setError(false);
    const res = await getData(`user?page=${pagination}${query}`);
    setLoading(false);
    setRefresh(false);
    if (res?.status === "success") {
      setUsers(res.users.data);
      setUserLinks(res.users);
      return;
    }

    setError(true);
    if (res.message) {
      toast.error(res.message);
      return;
    }
    toast.error("error fetching data");
  };

  useEffect(() => {
    if (
      pagination &&
      ((users.length === 0 && didMount) || (refresh && didMount))
    ) {
      getUsers();
    }
  }, [didMount, pagination, users.length, refresh]);

  useEffect(() => {
    if (userId && user === null) {
      getUser(userId);
    }
  }, [didMount, userId]);

  const updateUser = async (data, id) => {
    setLoading(true);
    setError(null);
    const res = await putData("user/" + id, data);
    setLoading(false);
    console.log("22323232");
    console.log(res);
    if (res?.status === "success") {
      toast.success("User successfully updated");
      return;
    }

    setError(true);
    if (res.message) {
      toast.error(res.message);
      setError(res.message);
      return;
    }
    toast.error("error posting data");
  };

  return {
    error,
    loading,
    users,
    userLinks,
    verificationTypes,
    roles,
    updateUser,
    user,
    setRefresh,
    setQuery,
  };
};

export default useUser;
