import {getData} from '../../utils/fetchData'




export  const  getStats = async() => {
    try {
        const res = await getData('dashboard-stats');
        if (res.dashboard_stats) {
            return res.dashboard_stats;
        }
    } catch(error){
        return null;
    }
}

export  const  getPlans = async() => {
    try {
        const res = await getData('dashboard-plans-summary');
        if (res.dashboard_plans_summary) {
            return res.dashboard_plans_summary;
        }
    } catch(error){
        return null;
    }
}

export  const  getPlansInfo = async() => {
    try {
        const res = await getData('dashboard-info-plan-summary');
        if (res.dashboard_info_plan_Summary) {
            return res.dashboard_info_plan_Summary;
        }
    } catch(error){
        return null;
    }
}


export  const  getBanners = async() => {
    try {
        const res = await getData('banner');
        if (res.banners) {
            return res.banners;
        }
    } catch(error){
        return null;
    }
}


