import { useState } from "react";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../components/AdminShared/RequestsDetailsButton";
import RequestDetailsForm from "../../../components/AdminShared/RequestDetailsForm";
import TableBackground from "../../../components/AdminShared/TableBackground";
import { useParams } from "react-router-dom";
import Loading from "../../../components/shared/Loading";
import PageStatus from "../../../components/PageStatus/PageStatus";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { useChallengeDetails } from "../../../hooks/Admin/ChallengeRequests/useChallengeDetails";

export default function ChallengeRequestDetails() {
  const [login, setLogin] = useState(null);
  const [investorPass, setInvestorPass] = useState(null);
  const [Pass, setPass] = useState(null);
  const { id } = useParams();
  const {user, handleSubmit: submitUseData, loading, submitLoading, error, handleBack} = useChallengeDetails(id)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Pass === null || investorPass === null || login === null) {
      return;
    }
    const formData = {
      login: login,
      password: Pass,
      investor_password: investorPass,
      user_challenge_id: id,
    };

    submitUseData(formData)
  };

  return (
    <>
      <PageStatus
        category={"Challenge"}
        currentPage={"Challenge requests"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage text={error}>
            <button className="-mb-1 border-0 btn btn-sm !text-white bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] " onClick={handleBack}>
              Back
            </button>
          </ErrorMessage>
        ) : (
          <>
            <RequestDetailsForm>
              <RequestDetailsInput
                title="Email"
                value={user.user.email}
                disabled
                
              />
              <RequestDetailsInput
                title="Full Name"
                value={user.user.first_name + " " + user.user.last_name}
                disabled
              />
              <RequestDetailsInput
                title="Phone"
                value={user.user.phone}
                disabled
              />
              <RequestDetailsInput
                title="Platform"
                value={user.platform}
                disabled
              />
              <RequestDetailsInput
                title="Broker"
                value={user.broker}
                disabled
              />
              <RequestDetailsInput
                title="Challenge"
                value={user.challenge}
                disabled
              />
              <RequestDetailsInput
                title="Server"
                value={user.server}
                disabled
              />
              <RequestDetailsInput
                title="Login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
              <RequestDetailsInput
                title="Password"
                value={Pass}
                onChange={(e) => setPass(e.target.value)}
              />
              <RequestDetailsInput
                title="Investor Password"
                value={investorPass}
                onChange={(e) => setInvestorPass(e.target.value)}
              />
            </RequestDetailsForm>

            <RequestDetailsButtonLayout>
              <RequestDetailsButton
                onclick={handleSubmit}
                title="Save"
                optClass="!btn-success"
                disabled={submitLoading}
              >
                {submitLoading && (
                  <span className="loading loading-dots loading-sm"></span>
                )}
              </RequestDetailsButton>
              <RequestDetailsButton
                onclick={handleBack}
                title="Cancel"
                optClass="btn-error"
              />
            </RequestDetailsButtonLayout>
          </>
        )}
      </TableBackground>
    </>
  );
}
